<template>
    <div>
        <b-container class="mt-2" fluid>
            
            <b-row class="mt-2">
                <b-col md="2" class="input-group-text no-border-radius-right">ログインID</b-col>
                <b-col md="8" class="p-0 no-border-radius-left"> 
                   <b-form-input class="no-border-radius-left" v-model="email" disabled></b-form-input>
                </b-col>
                <b-col md="1" class="p-0"> 
                    <router-link to="/admin/account/detail">
                        <b-button variant="outline-success" class="h-100 w-100">編集</b-button>
                    </router-link>
                </b-col>
            </b-row>
        </b-container>
     </div>
</template>

<script lang="js">
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import adminMixin from "../../../mixin/AdminMixin";
export default Vue.extend({
    data() {
        return {
           email: ""
        };
    },
    mixins: [adminMixin],
    computed: {
        ...mapGetters([
            'getTokens',
        ]),
    },
    methods: {
        ...mapActions(['handleCrudAPIAdmin']),
        async fetchData(objCondition = {}) {
            let objPagram = {
                method: "get",
                urlAPi: "account",
                objCondition: objCondition
            };
            let dataResult = await this.handleCrudAPIAdmin(objPagram);
            this.email = dataResult.data.email;
        },
    },
    async created() {
        await this.fetchData();
    },
})
</script>

<style lang="css" scoped>
    .input-group-text, th {
    width: 200px;
    }
    
    .search {
        width: initial;
    }
    
    .btn-group .btn {
        margin-right: 10px;
    }
</style>