import Vue from "vue";
import { BootstrapVue } from 'bootstrap-vue'
import LoadScript from 'vue-plugin-load-script';
import App from '@/App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//router
import router from '@/routes';

//store
import store from '@/store';

// Install BootstrapVue
Vue.use(BootstrapVue)
    // Install LoadScript
Vue.use(LoadScript);
// Vue.prototype.$auth = document.querySelector("meta[name='auth']").getAttribute('content');
library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
}).$mount('#app');