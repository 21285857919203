import Vue from 'vue'
import VueRouter from 'vue-router'

import loginPage from './pages/admin/Login'
import accountPage from './pages/admin/account/Account'
import accountDetailPage from './pages/admin/account/AccountDetail'
import adminPostPage from './pages/admin/admin-post/AdminPost'
import adminPostDetailPage from './pages/admin/admin-post/AdminPostDetail'
import adminPostNewPage from './pages/admin/admin-post/AdminPostNew'
import categoryPage from './pages/admin/category/Category'
import categoryDetailPage from './pages/admin/category/CategoryDetail'
import categoryNewPage from './pages/admin/category/CategoryNew'
import jobPage from './pages/admin/jobs/Job'
import jobDetailPage from './pages/admin/jobs/JobDetail'
import ngWordPage from './pages/admin/ng-word/NgWord'
import userPostPage from './pages/admin/user-post/UserPost'
import userPostDetailPage from './pages/admin/user-post/UserPostDetail'
import userPage from './pages/admin/users/User'
import userDetail from './pages/admin/users/UserDetail'
import skillPage from './pages/admin/validation/Skill'
import idCardPage from './pages/admin/validation/IdCard'
import validateDetailPage from './pages/admin/validation/ValidateDetail'

import listvedeo from './pages/admin/video/listvideo'

import terms from './pages/Terms'
import privacy from './pages/Privacy'
Vue.use(VueRouter)

var prefixAdmin = "/"

const router = new VueRouter({
    mode:'history',
    routes: [
        {
            path: '',
            component: () => import('./layouts/Login'),
            name:'login',
            children: [
                {
                    path: prefixAdmin,
                    name: 'admin-login',
                    component: loginPage
                }
            ]
        },
        {
            path: '/terms',
            name:'terms',
            component: terms
        },
        {
            path: '/privacy',
            name:'privacy',
            component: privacy
        },
        {
            path: '/admin',
            component: () => import('./layouts/Login'),
            name:'admin-login',
            children: [
                {
                    path: prefixAdmin,
                    name: 'admin-login',
                    component: loginPage
                }
            ]
        },
        
        {
            path: '/admin/users',
            name: 'admin-users',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-users',
                    component: userPage,
                    meta: {
                        breadcrumb: [
                          { text: 'ユーザー管理', href: '#' },
                        ],
                        pageTitle: 'ユーザー管理一覧',
                    }
                }
            ]
        },
        
        {
            path: '/admin/users/detail',
            name: 'admin-users-detail',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-users-detail',
                    component: userDetail,
                    meta: {
                        breadcrumb: [
                          { text: 'ユーザー管理', href: '/admin/users' },
                          { text: '詳細', href: '#' },
                        ],
                        pageTitle: 'ユーザー管理 詳細',
                    }
                }
            ]
        },
          
        {
            path: '/admin/validation/skills',
            name: 'adminvalidation-skills',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-validation-skill',
                    component: skillPage,
                    meta: {
                        breadcrumb: [
                          { text: 'ユーザースキル/資格一覧', href: '#' },
                        ],
                        pageTitle: 'ユーザースキル/資格一覧',
                    }
                }
            ]
        },
        
        {
            path: '/admin/validation/id-card',
            name: 'admin-validation-id-card',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-validation-id-card',
                    component: idCardPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "身分証を認証する", 
                                href: "/admin/validation/id-card" 
                            }
                        ],
                        pageTitle: '身分証を認証する',
                    }
                }
            ]
        },
        
        {
            path: '/admin/validation/id-card/detail',
            name: 'admin-validation-id-card-detail',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-validation-detail',
                    component: validateDetailPage,
                    meta: {
                        breadcrumb: [
                          { 
                            text: "身分証を認証する", 
                            href: "/admin/validation/id-card" 
                        },
                          { text: '確認', href: '#' },
                        ],
                        pageTitle: '認証依頼管理',
                    }
                }
            ]
        },
        
        {
            path: '/admin/validation/skills/detail',
            name: 'admin-validation-skills-detail',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-validation-detail',
                    component: validateDetailPage,
                    meta: {
                        breadcrumb: [
                          { 
                            text: "ユーザースキル/資格一覧", 
                            href: "/admin/validation/skills"
                        },
                          { text: '確認', href: '#' },
                        ],
                        pageTitle: '認証依頼管理',
                    }
                }
            ]
        },
        
        {
            path: '/admin/user-post',
            name: 'admin-user-post',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-user-post',
                    component: userPostPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "自由投稿管理", 
                                href: "#" 
                            }
                        ],
                        pageTitle: '自由投稿管理',
                    }
                }
            ]
        },
        
        {
            path: '/admin/user-post/detail',
            name: 'admin-user-post-detail',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-user-post-detail',
                    component: userPostDetailPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "自由投稿管理", 
                                href: "/admin/user-post" 
                            },
                            { 
                                text: "詳細", 
                                href: "#" 
                            }
                        ],
                        pageTitle: '自由投稿管理/詳細',
                    }
                }
            ]
        },
        
        {
            path: '/admin/account',
            name: 'admin-account',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-account',
                    component: accountPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "ログインID/PW 管理", 
                                href: "#" 
                            }
                        ],
                        pageTitle: 'ログインID/PW 詳細',
                    }
                }
            ]
        },
        
        {
            path: '/admin/account/detail',
            name: 'admin-account-detail',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-account-detail',
                    component: accountDetailPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "ログインID/PW 管理", 
                                href: "/admin/account" 
                            },
                            { 
                                text: "編集", 
                                href: "#" 
                            }
                        ],
                        pageTitle: 'ログインID/PW 編集',
                    }
                }
            ]
        },
        
        {
            path: '/admin/admin-post',
            name: 'admin-admin-post',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-admin-post',
                    component: adminPostPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "運営からのお知らせ", 
                                href: "#" 
                            }
                        ],
                        pageTitle: '運営からのお知らせ',
                    }
                }
            ]
        },
        
        {
            path: '/admin/admin-post/detail',
            name: 'admin-admin-post-detail',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-admin-post-detail',
                    component: adminPostDetailPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "運営からのお知らせ", 
                                href: "/admin/admin-post" 
                            },
                            { 
                                text: "編集", 
                                href: "#" 
                            }
                        ],
                        pageTitle: '運営からのお知らせ/編集',
                    }
                }
            ]
        },
        
        {
            path: '/admin/admin-post/new',
            name: 'admin-admin-post-new',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-admin-post-new',
                    component: adminPostNewPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "運営からのお知らせ", 
                                href: "/admin/admin-post" 
                            },
                            { 
                                text: "詳細", 
                                href: "#" 
                            }
                        ],
                        pageTitle: '運営からのお知らせ/新規投稿',
                    }
                }
            ]
        },
        
        {
            path: '/admin/category',
            name: 'admin-category',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-category',
                    component: categoryPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "スキル/資格マスタ", 
                                href: "#" 
                            }
                        ],
                        pageTitle: 'スキル/資格マスタ',
                    }
                }
            ]
        },
        
        {
            path: '/admin/category/new',
            name: 'admin-category-new',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-category-new',
                    component: categoryNewPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "スキル/資格マスタ", 
                                href: "admin/category" 
                            },
                            { 
                                text: "詳細", 
                                href: "#" 
                            }
                        ],
                        pageTitle: 'スキル/資格新規登録',
                    }
                }
            ]
        },
        
        {
            path: '/admin/category/detail',
            name: 'admin-category-detail',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-category-detail',
                    component: categoryDetailPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "スキル/資格マスタ", 
                                href: "admin/category" 
                            },
                            { 
                                text: "編集", 
                                href: "#" 
                            }
                        ],
                        pageTitle: 'スキル/資格編集',
                    }
                }
            ]
        },
        
        {
            path: '/admin/jobs',
            name: 'admin-job',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-jobs',
                    component: jobPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "お仕事依頼管理", 
                                href: "#" 
                            }
                        ],
                        pageTitle: 'お仕事依頼リスト管理',
                    }
                }
            ]
        },
        
        {
            path: '/admin/jobs/detail',
            name: 'admin-jobs-detail',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-jobs-detail',
                    component: jobDetailPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "お仕事依頼管理", 
                                href: "/admin/jobs" 
                            },
                            { 
                                text: "詳細", 
                                href: "#" 
                            }
                        ],
                        pageTitle: 'お仕事依頼リスト管理/詳細',
                    }
                }
            ]
        },
        
        {
            path: '/admin/ngWord',
            name: 'admin-ngWord',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-ngWord',
                    component: ngWordPage,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "NGワード管理", 
                                href: "#" 
                            }
                        ],
                        pageTitle: 'NGワード管理',
                    }
                }
            ]
        },
// add route
        {
            path: '/admin/video',
            name: 'admin-video',
            component: () => import('./layouts/Admin'),
            children: [
                {
                    path: "" ,
                    name: 'admin-video',
                    component: listvedeo,
                    meta: {
                        breadcrumb: [
                            { 
                                text: "動画アップロード", 
                                href: "#" 
                            }
                        ],
                        pageTitle: '動画アップロード',
                    }
                }
            ]
        }
    ]
})

export default router;

