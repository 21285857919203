import Vue from 'vue'
import Vuex from 'vuex'
import router from '../routes';
// import admin from './Admin';
const axiosInstance = require('axios');

import { URL_API_LOGIN_ADMIN, URL_API_ADMIN } from "../common/config";
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        auth: localStorage.getItem('auth')
    },
    getters: {
        getTokens(rootState) {
            return rootState.auth;
        }
    },
    mutations: {
        SET_AUTH(state, auth) {
            state.auth = auth;
            localStorage.setItem('auth', auth);
        },
        REMOVE_AUTH(state) {
            state.auth = "";
            localStorage.removeItem('auth');
        }
    },
    actions: {
        async loginAdminAct({ commit }, params = null) {
            console.log(commit);
            try {  
                const result = await axiosInstance['post'](URL_API_LOGIN_ADMIN, params);
                if (result.status == 200) {
                    return {
                        ok: true,
                        data: result,
                        error: null
                    }
                } else {
                    return {
                        ok: false,
                        error: result.data.message
                    }
                }
            } catch (error) {
                return {
                    ok: false,
                    error: error.message
                }
            } 
        },
        async Submitcase({ commit }, params = null) {
            console.log(commit);
            const accessToken = this.getters.getTokens;
        
            try {
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                console.log(params);
            } catch (error) {
                return {
                    ok: false,
                    error: error.message
                }
            } 
        },
        async handleCrudAPIAdmin({ commit , dispatch , app }, objPagram) {
                    console.log(commit);
                    console.log(app);
                    let method =  objPagram.method;
                    let urlAPi =  URL_API_ADMIN + '/' + objPagram.urlAPi;
                    let objCondition = objPagram.objCondition;
                    const accessToken = this.getters.getTokens;
                    if(accessToken){
                        try {
                            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                            const result = await axiosInstance[method](urlAPi, objCondition);
                            if (result.status == 200) {
                                if(result.data.logout)
                                    dispatch('logout');
                                return {
                                    ok: true,
                                    data: result.data,
                                    error: result.data.error
                                }
                            } else {
                                return {
                                    ok: false,
                                    error: result.error
                                }
                            }
                        } catch (error) {
                            let code =  error.response.status;
                            if(code == 401) 
                            {
                                router.push( {path: '/'} );
                                localStorage.removeItem('auth');
                            }
                            return {
                                code: code,
                                ok: false,
                                error: error.response.data.errors
                            }
                        }
                    }else{
                        router.push( {path: '/'} );
                        localStorage.removeItem('auth');
                    }
                },
    }
    // modules: {
    //     admin : admin // regist in page
    // }
});

export default store;