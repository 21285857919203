<template>
  <div>
    <div class="text-center" v-if="!loaded">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <b-alert variant="success" v-model="showDismissibleAlert" hide dismissible
      >ー動画がアップロードされました</b-alert
    >
    <!-- カテゴリを削除しました -->
    <b-alert variant="success" v-model="showDismissiblecase" hide dismissible
      >カテゴリを削除しました</b-alert
    >
    <!-- error2      -->
    <b-alert
      variant="danger"
      v-for="(val, index) in error2"
      :key="index"
      show
      >{{ val }}</b-alert
    >
    <!-- <b-alert variant="success" show v-if="$route.params.update_success == '1' && updated_success">運営からのお知らせを編集しました</b-alert> -->
    <!-- <b-alert variant="success" show v-if="$route.params.success_remove == '1' || deleted_success">削除しました</b-alert> -->

    <div>
      <b-modal ref="my-modal" hide-footer title="カテゴリ追加">
        <div class="d-block text-center">
          <b-alert
            style="text-align: start"
            show
            v-for="(item, index) in errorcase"
            :key="index"
            variant="danger"
            >{{ item[0] }}</b-alert
          >
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="sm"
            label="タイトル"
            label-for="input-sm"
          >
            <b-form-input v-model="title"></b-form-input>
          </b-form-group>
        </div>
        <b-row class="my-1">
          <b-col sm="6">
            <b-button
              class="mr-2"
              variant="outline-success"
              block
              @click="Submit"
              >登録</b-button
            >
          </b-col>
          <b-col sm="6">
            <b-button
              class="mr-2"
              variant="outline-danger"
              block
              @click="Closemymodal"
              >閉じる</b-button
            >
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div>
      <b-modal size="xl" ref="add-vedeo" hide-footer title="動画を追加する">
        <div class="d-block text-center">
          <div>
            <!-- <div>{{errors}}</div> .cate_id[0] -->

            <b-alert
              style="text-align: start"
              show
              v-for="(item, index) in errors"
              :key="index"
              variant="danger"
              >{{ item[0] }}</b-alert
            >
            <!-- <b-alert  v-for="(value,index) in errors" :key="index" variant="danger" >{{index}}</b-alert>   dismissible   -->
            <!-- errors -->
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label-size="sm"
              label="カテゴリ"
              label-for="input-sm"
            >
              <b-form-select
                v-model="vedeoadd.cate_id"
                class="selectBoxTag bs__select--size"
                :options="skillList"
              ></b-form-select>
            </b-form-group>
            <!-- vedeoadd -->
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label="タイトル"
              label-for="Title"
            >
              <b-form-input
                id="input-default"
                v-model="vedeoadd.title"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label-size="lg"
              label="URL"
              label-for="input-lg"
            >
              <b-form-input
                id="input-lg"
                v-model="vedeoadd.url"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label-size="lg"
              label="日付"
              label-for="input-lg"
            >
              <b-form-datepicker
                id="example-datepicker"
                size="lg"
                v-model="vedeoadd.created_at"
                class="mb-2"
                placeholder="YYYY-MM-DD"
              ></b-form-datepicker>
            </b-form-group>
          </div>
        </div>
        <!-- hidevideo -->
        <b-row class="my-1">
          <b-col sm="6">
            <b-button
              label-cols="4"
              variant="outline-success"
              block
              @click="Addvideo"
              >登録</b-button
            >
          </b-col>
          <b-col sm="6">
            <b-button
              class="mr-2"
              variant="outline-danger"
              block
              @click="hidevideo"
              >閉じる</b-button
            >
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <b-container class="mt-2" fluid>
      <b-alert variant="success" show v-if="$route.params.success == '1'"
        >情報を更新しました</b-alert
      >

      <!-- End company input -->

      <!-- Skills List -->
      <b-row class="mt-2">
        <b-col md="2" class="input-group-text no-border-radius-right">
          カテゴリ
        </b-col>
        <b-col md="6" class="p-0 no-border-radius-left">
          <b-button-group size="sm" class="h-100 ml-2 mt-1">
            <b-form-checkbox-group
              id="skills-checkbox"
              v-model="skillListSelected"
              @change="Listvedeo"
              :options="skillList"
              name="flavour-1"
            ></b-form-checkbox-group>
          </b-button-group>

          <!-- <i class="fas fa-minus"></i> //fetchData -->
        </b-col>
        <b-col md="4">
          <div
            style="display:flex;align-items: center;
    justify-content: space-between; 
    max-width: 320px; 
    margin-left: auto; 
    margin-right: 0;"
          >
            カテゴリ追加
            <b-button
              style="margin-left: 5px; font-size: 13px;"
              class="mr-2"
              variant="btn btn-primary"
              @click="showModal"
              ><i class="fa fa-plus" aria-hidden="true"></i
            ></b-button>
            カテゴリ削除
            <b-button
              style="margin-left: 5px; font-size: 13px;"
              class="mr-2"
              variant="btn btn-danger"
              v-on:click="removecase"
              ><i class="fas fa-minus"></i
            ></b-button>
          </div>
        </b-col>
      </b-row>
      <!-- End skills list -->
      <b-row class="mt-2">
        <b-col md="2">
          <!-- Page limit -->
          <div class="wrapper mb-4">
            <b-form-select
              v-model="perPage"
              @change="Listvedeo"
              class="selectBoxTag bs__select--size"
              :options="paginationSelectBox"
            ></b-form-select>
          </div>
          <!-- End page limit -->
        </b-col>

        <b-col md="10 text-left">
          <b-button
            class="mr-2"
            variant="btn btn-outline-primary"
            v-on:click="showvideo"
            >動画を追加する</b-button
          >
          <b-button
            class="mr-2"
            variant="btn btn-outline-secondary"
            v-on:click="statusvideo(1)"
            >表示</b-button
          >
          <b-button
            class="mr-2"
            variant="btn btn-outline-warning"
            v-on:click="statusvideo(0)"
            >非表示</b-button
          >
          <b-button
            class="mr-2"
            variant="btn btn-outline-success"
            v-on:click="updatevedeo"
            >更新
          </b-button>
          <b-button
            class="mr-2"
            variant="btn btn-outline-danger"
            v-on:click="Deletevideo"
            >削除
          </b-button>
        </b-col>
      </b-row>
      <!-- End skills list -->
      <b-row class="mt-2">
        <!-- @click="showModal" <b-col md="6 text-left">
                
                
                <b-button class="mr-2" variant="outline-success" v-on:click="exportCSV">CSVダウンロード</b-button>
            </b-col> -->

        <b-col md="4 text-left">
          <paginate
            v-if="renderPaginateComponent"
            :page-count="totalPage"
            :click-handler="clickCallbackPagination"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination m-0'"
          >
          </paginate>
        </b-col>
      </b-row>
    </b-container>
    <!-- List user table -->
    <b-table
      responsive
      :bordered="bordered"
      ref="selectableTable"
      :select-mode="selectMode"
      :items="listUser"
      :fields="fields"
    >
      <!-- selectable @row-selected="onRowSelected"  -->
      <template #cell(update)="row">
        {{ row.label }}
        <b-button
          variant="outline-success"
          v-on:click="updatevedeo($event, row.item)"
        >
          更新 </b-button
        >&nbsp;
        <b-button
          variant="outline-danger"
          v-on:click="Deletevideo($event, row.item)"
        >
          削除
        </b-button>
      </template>
      <template #head(stt)="data">
        {{ data.label }}
        <input
          type="checkbox"
          v-model="chosseAll"
          value="true"
          @click="checkall"
        />
      </template>

      <template #cell(stt)="row">
        {{ row.item.index }}
        <input type="checkbox" v-model="row.item.check" :checked="chosseAll" />
        <!-- :checked="chosseAll"  row.item.check -->
      </template>
      <template #head(cate_id)="data">
        {{ data.label }}
        <b-button
          variant="outline-primary"
          size="sm"
          v-on:click="updateOrder('cate_id')"
        >
          <i class="fas fa-sort"></i>
        </b-button>
      </template>
      <template #head(created_at)="data">
        {{ data.label }}
        <b-button
          variant="outline-primary"
          size="sm"
          v-on:click="updateOrder('created_at')"
        >
          <i class="fas fa-sort"></i>
        </b-button>
      </template>
      <template #head(title)="data">
        {{ data.label }}
        <b-button
          variant="outline-primary"
          size="sm"
          v-on:click="updateOrder('title')"
        >
          <i class="fas fa-sort"></i>
        </b-button>
      </template>
      <!-- statusshow -->
      <template #cell(status)="row">
        {{ statusshow(row.item.status) }}
      </template>
      <template #cell(created_at)="row">
        {{ formatDate(row.value) }}
      </template>
      <template #cell(cate_id)="row">
        {{ Case_text(row.item.cate_id) }}
      </template>
      <template #cell(title)="row">
        <b-form-input v-model="row.item.title" />
      </template>
      <template #cell(url)="row">
        <b-form-input v-model="row.item.url" />
      </template>

      <!-- row -->
      <!-- <template #cell(url)="row"> updateOrder
         {{row.item.url}}
               <input type="text" v-bind:value="row.item.url" @click="Text(row.item)" v-model="ListvedeoSelected" />
    </template> -->
    </b-table>
    <!-- End List user table -->
    <div class="paginationBLock">
      <paginate
        v-if="renderPaginateComponent"
        :page-count="totalPage"
        :click-handler="clickCallbackPagination"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'pagination pb-3'"
      ></paginate>
    </div>
  </div>
</template>

<script lang="js">
import {
    mapActions,
    mapGetters,
} from "vuex"
import Vue from 'vue'
import adminMixin from "../../../mixin/AdminMixin";
import { PAGE_SIZE, URL_API } from "../../../common/config";
// declare interface User {
//     created_at: string,
//     username: string,
//     birthday: any,
//     get_connect_areas:  Array < any >,
//     department: string,
//     status: string,
//     ID_validate: string,
//     get_certificates: Array < any > ,
// }

// declare interface Skill {
//     _id: string,
//     name: string,
// }

export default Vue.extend({
    data() {

        return {
            showDismissiblecase: false,
            chosseAll: false,
            showDismissibleAlert: false,
            error2: {},
            errorcase: {},
            errors: {},
            loaded: false,
            ListvedeoSelected: [],
            title:'',
            totalPage: 0,
            pageCurrent: 1,
            perPage: PAGE_SIZE,
            currentPage: 1,
            nameSearch: '',
            areaSearch: '',
            departmentSelected: -1,
            searchButtonStatus: false,
            skillStatusSelected: [],
            reportSelected: '',
            listUser: [],
            vedeoadd: [],
            selected: [],
            bordered: true,
            selectMode: "multi",
            renderPaginateComponent: true,
            paginationSelected: 0,
            paginationSelectBox: [{
                    value: 0,
                    text: "表示件数切り替え",
                    disabled: true,
                },
                {
                    value: 20,
                    text: "20",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                }
            ],
            departmentSelectBox: [{
                    value: -1,
                    text: "表示件数切り替え",
                    disabled: true,
                },
                {
                    value: 0,
                    text: "保険会社専属",
                },
                {
                    value: 1,
                    text: "保険代理店",
                },
                {
                    value: 2,
                    text: "その他",
                },
            ],
            skillListSelected: [],
            skillList: [],
            skillStatus: [{
                    item: 0,
                    name: '認証待ち'
                },
                {
                    item: 1,
                    name: '認証済'
                },
                {
                    item: -1,
                    name: '未認証'
                }
            ],
            statusSelected: [],
            userStatus: [
                {
                    item:   1,
                    name: '有効'
                },
                {
                    item:   0,
                    name: '無効'
                },
                {
                    item:   2,
                    name: '退会'
                }
            ],
            userReport: [
                {
                    value: 1,
                    text: 'あり'
                },
                {
                    value:  0,
                    text: 'なし'
                }
            ],
            options: [{
                    index: 0,
                    value: null,
                    text: 'Please select an option'
                },
                {
                    index: 1,
                    value: 'a',
                    text: 'This is First option'
                },
                {
                    index: 2,
                    value: 'b',
                    text: 'Selected Option'
                },
                {
                    index: 3,
                    value: {
                        C: '3PO'
                    },
                    text: 'This is an option with object value'
                },
                {
                    index: 4,
                    value: 'd',
                    text: 'This one is disabled',
                    disabled: true
                }
            ],
            items: [

            ],
            fields: [
                  {
                    key: 'stt',
                    label: '全選択',
                    class: 'text-center w-5 p-0',
                },
                // {
                //     key: 'row',
                //     label: 'STT',
                //     class: 'text-center w-5 p-0',
                // },
                 {
                    key: 'cate_id',
                    label: 'カテゴリ',
                    class: 'text-center w-5 p-0'
                },

                {
                    key: 'created_at',
                    label: '日付',
                    class: 'text-center w-10 p-0'
                },
                {
                    key: 'status',
                    label: 'ステータス',
                    class: 'text-center w-10 p-0'
                },
                {
                    key: 'title',
                    label: 'タイトル',
                    class: 'text-center w-5 p-0'
                },
                {
                    key: 'url',
                    label: 'URL',
                    class: 'text-center w-10 p-0'
                },

                {
                    key: 'update',
                    label: '',
                    class: 'text-center w-5 p-0'
                },
                // {
                //     key: 'skill',
                //     label: 'スキル/資格',
                //     class: 'text-center w-10 p-0'
                // },
                // {
                //     key: 'skillValidate',
                //     label: 'スキル認証',
                //     class: 'text-center w-10 p-0'
                // },
                // {
                //     key: 'ID_validate',
                //     label: '身分証認証',
                //     class: 'text-center w-10 p-0'
                // },
                // {
                //     key: 'status',
                //     label: 'ステータス',
                //     class: 'text-center w-5 p-0'
                // },
                // {
                //     key: 'reportHistory',
                //     label: '通報履歴',
                //     class: 'text-center w-5 p-0'
                // },
                // {
                //     key: 'edit',
                //     label: ''
                // },
            ],
            orderName: '',
            orderType: false
        };
    },
    created(){
      this.creat();
    },
    mixins: [adminMixin],

    computed: {
        ...mapGetters([
            'getTokens',
        ]),

        rows() {
            return this.items.length;
        },

    },
    methods: {
        ...mapActions(["handleCrudAPIAdmin"]),
        checkall(){
            //check chosseAll
            var val = false;
            if(this.chosseAll){ val = false; }else{ val = true;  }
               this.listUser.forEach(item => {
                    item.check = val;
               });
        },
        Addvideo(){

            let objCondition = {};
            objCondition.cate_id =  this.vedeoadd.cate_id;
            objCondition.created_at =  this.vedeoadd.created_at;
            objCondition.title =  this.vedeoadd.title;
            objCondition.url =  this.vedeoadd.url;
            objCondition.is_deleted = false;
            //is_deleted
                let objParam = {
                action: "detail",
                method: "post",
                urlAPi: "Video/store",
                objCondition: objCondition
            };

            this.handleCrudAPIAdmin(objParam).then(data=>{

                    if(data.code == 422){
                        this.errors = data.error;

                    }else{
                        this.fetchData();
                        this.showDismissibleAlert=true;
                        // this.$refs.table.refresh();
                        this.$refs['add-vedeo'].hide()
                        this.vedeoadd = [];
                    }

            });


        },
        onSelected(items) {
            if(event.target.checked){
                this.selected.push(items)
            }
      },
      statusshow(stas){
        var value = '';
          switch(stas) {
                case 1:
                    value = "表示";
                    break;
                default:
                     value = "非表示";
            }
        return value;
      },
      statusvideo(status){
            let arr = [];
            this.listUser.forEach((item) => {

                if(item.check==true){
                     item.status = status;
                    arr.push(item);
                }
            })

            if(arr.length<1){  return ; }
            let objCondition = {};
            objCondition.data = arr;
             let objParam = {
            action: "detail",
            method: "post",
            urlAPi: "Video/update",
            objCondition: objCondition
            };

            this.handleCrudAPIAdmin(objParam).then(data=>{
            if(data.code == 422)
                this.errors = data.error;
            else
                this.fetchData();
                this.showDismissibleAlert=true;
            });
      },
      Deletevideo(even,val = null){
      this.error2 = [];
          if(!confirm("削除しますか ?")){
              return;
          }

            let arr = [];
    if(val==null){
            this.listUser.forEach((item) => {
                if(item.check==true){
                    arr.push(item);
                }
            })
    }else{
         arr.push(val);
    }
            if(arr.length<1){
                alert("チェックボックスにチェックを入れてください"); return;
            }
            let objCondition = {};
            objCondition.data = arr;
            let objParam = {
            action: "detail",
            method: "post",
            urlAPi: "Video/delete",
            objCondition: objCondition
            };

            this.handleCrudAPIAdmin(objParam).then(data=>{

            if(data.code == 422)
                this.errors = data.error;

            else
                // this.$refs['my-modal'].hide();
                // this.creat();
                //return this.$router.push({ name: 'admin-video', params:{success:"1"}});
                this.fetchData();
                this.showDismissibleAlert=true;
                this.$refs.table.refresh();
            });
      },
      updatevedeo(even,val = null){

    this.error2 = [];
    var check = {};
    var flag = false;
    var arry = [];

    if(val==null){
        this.listUser.forEach((item) => {
            if(item.check==true){

                if(item.title==null||item.title==''){
                    check.title = 'タイトル ログイン'; flag = true;
                }
                if(item.url==null||item.url==''){
                    check.url = 'URL ログイン';  flag = true;
                }
                arry.push(item);
            }
        });
    }else{
        if(val.title==null||val.title==''){
                    check.title = 'タイトル ログイン'; flag = true;
        }
        if(val.url==null||val.url==''){
                    check.url = 'URL ログイン';  flag = true;
        }
        arry.push(val);
    }
        if(flag){
            this.error2 = check;
            this.showDismissibleAlert=false;
            return;
        }
        if(arry.length<1){
            alert("チェックボックスにチェックを入れてください"); return;
        }

        let objCondition = {};
        objCondition.data =  arry;//this.selected;

        let objParam = {
          action: "detail",
          method: "post",
          urlAPi: "Video/update",
          objCondition: objCondition
        };

        this.handleCrudAPIAdmin(objParam).then(data=>{
          if(data.code == 422)
            this.errors = data.error;
          else
            this.fetchData();
            this.showDismissibleAlert=true;
          });
    },
       Case_text(value){
        let text = '';
            this.skillList.forEach((item) => {
                if(item.value==value){
                    text = item.text
                    return ;
                }
            })
        return text;
        },
        async creat() {
            this.skillList = [];
            let objPagram = {
                method: "get",
                urlAPi: "category",
            };
            let dataResult = await this.handleCrudAPIAdmin(objPagram);

            let dataList = dataResult.data.data;
            // this.totalPage = dataResult.data.last_page;
            // this.listUser = [] as User[] ;
            if (dataResult.ok == true) {
                dataList.forEach((item) => {

                    let objSkill = {
                        text: item.title,
                        value: item._id
                    }
                    this.skillList.push(objSkill);
                });
            }
            await this.fetchData();
    },
    Listvedeo(){
             this.fetchData();
        },
    removecase(){

        if(this.skillListSelected.length<1){
            alert("カテゴリを選択していません !"); return false;
        }
         let objCondition = {
             _id: this.skillListSelected,
        };
        let objParam = {
          action: "detail",
          method: "post",
          urlAPi: "video-category/delete",
          objCondition: objCondition
        };

        this.handleCrudAPIAdmin(objParam).then(data=>{
          if(data.code == 422)
            this.errors = data.error;
          else
            this.showDismissiblecase = true;
            this.skillListSelected = [];
            this.fetchData();
            this.creat();
          });
    },
      Submit(){
      let objCondition = {
          title:this.title,
      };
        let objParam = {
          action: "detail",
          method: "post",
          urlAPi: "category/store",
          objCondition: objCondition
        };

        this.handleCrudAPIAdmin(objParam).then(data=>{

          if(data.code == 422)
            this.errorcase = data.error;
          else
            this.$refs['my-modal'].hide();
            this.creat();
            //return this.$router.push({ name: 'admin-video', params:{success:"1"}});
          });
      },
      showModal() {
        this.title = '';
        this.errorcase = [];
        this.$refs['my-modal'].show()
      },
      Closemymodal(){
        this.$refs['my-modal'].hide()
      },
      showvideo() {
      this.$refs['add-vedeo'].show();
      this.errors = [];
      },
      hidevideo() {
        this.$refs['add-vedeo'].hide();
      },
        onChangePagination() {
            this.perPage = this.paginationSelected;
        },
        onRowSelected(items) {
            this.selected = items;
        },
        fetchDataWithPaginationOrCondition: function () {
            let objCondition = {};
            if (this.searchButtonStatus == true) {
                // if (this.nameSearch !== "")
                //     objCondition.nameSearch  = this.nameSearch;
                // if (this.areaSearch !== "")
                //     objCondition.areaSearch = this.areaSearch;
                // if (this.departmentSelected !== -1)
                //     objCondition.departmentSelected = this.departmentSelected;
                // if (this.skillListSelected.length > 0)
                //     objCondition.skillListID = this.skillListSelected;
                // if (this.skillStatusSelected.length > 0)
                //     objCondition.skillStatusSelected = this.skillStatusSelected;
                // if (this.statusSelected.length > 0)
                //     objCondition.statusSelected = this.statusSelected;
                // if (this.reportSelected !== "" && this.reportSelected !== "-1")
                //     objCondition.reportSelected = this.reportSelected;
                if (this.orderName.length > 0) {
                    objCondition.orderName = this.orderName;
                    objCondition.orderType = this.orderType ? 'asc' : 'desc';
                }
            }
            this.fetchData(objCondition);
        },

        searchUser() {
            this.searchButtonStatus = true;
            this.fetchDataWithPaginationOrCondition();
        },

        clickCallbackPagination(pageNumber) {
            this.pageCurrent = pageNumber;
            this.fetchDataWithPaginationOrCondition();
        },

        getUserSkillName(certificates) {
            let nameSkill = '';
            certificates.forEach(function (item, key) {
                nameSkill += item.get_skill.name;
                if (key < certificates.length - 1) {
                    nameSkill += ' , ';
                }
            });
            return nameSkill;
        },

        getUserSkillValidate(certificates) {
            let verifiedStatusText = '';
            let notVerifiedStatusText = '';
            let pendingStatusText = '';
            certificates.forEach(function (item) {
                let statusSkill = item.status;
                if (statusSkill == 1)
                    verifiedStatusText += item.get_skill.name  + ' ';
                if (statusSkill == 0)
                    pendingStatusText += item.get_skill.name  + ' ';
                if (statusSkill == -1)
                    notVerifiedStatusText += item.get_skill.name  + ' ';
            });
            let textStatus = '';
            if(verifiedStatusText !== '')
                textStatus += '認証済: ' + verifiedStatusText;
            if(pendingStatusText !== '')
                textStatus += '認証待ち: ' + pendingStatusText;
            if(notVerifiedStatusText !== '')
                textStatus += '未認証: ' + notVerifiedStatusText;
            return textStatus;
        },

        getUserImageIDValidate(userItem) {
            if (userItem.ID_validate == 1)
                return "認証済"
            if (userItem.ID_validate == 0)
                return "認証待ち";
            return "未認証";
        },

        getUserStatus(userItem) {
            if (userItem.status == 1)
                return "有効"
            if (userItem.status == 0)
                return "無効";
            return "退会";
        },

        getArea(userItem) {
            if (userItem.get_connect_areas == null)
                return ""
            let area_text = [];
            for(let area of userItem.get_connect_areas) {
                area_text.push(area.name);
            }
           return area_text.join(", ");
        },

        getDeparment(department) {
            switch(department) {
                case 0:
                    return '保険会社専属';
                case 1:
                    return '保険代理店';
                default:
                    return 'その他';
            }
        },

        getAge(birthday) {
            let now = new Date().getFullYear();
            let year = new Date(birthday).getFullYear();
            return now - year;
        },

        status_text(status) {
            switch(status) {
                case 0:
                    return "認証待ち";
                case 1:
                    return "認証済";
                default:
                    return "未認証";
            }
        },

        async fetchData(objCondition = {}) {
            objCondition._id = this.skillListSelected;
            objCondition.page = this.pageCurrent;
            objCondition.size = this.perPage;
            let objPagram = {
                method: "post",
                urlAPi: "Video/list",
                objCondition: objCondition,
            };
            let dataResult = await this.handleCrudAPIAdmin(objPagram);

            let dataList= dataResult.data.data;
            this.listUser = [];

            if (dataResult.ok == true) {
                this.listUser = dataList;
            }
            this.chosseAll = false;
            this.loaded = true;
        },

        exportCSV() {
            const link = document.createElement('a');
            let url = URL_API+"/user_management/download?";
            let objCondition = [];
            if (this.nameSearch !== "")
                objCondition.push("nameSearch=" + this.nameSearch);
            if (this.areaSearch !== "")
                objCondition.push("areaSearch=" + this.areaSearch);
            if (this.departmentSelected !== -1)
                objCondition.push("departmentSelected=" + this.departmentSelected);
            if (this.skillListSelected.length > 0)
                objCondition.push("skillListID=" + this.skillListSelected.join(","));
            if (this.skillStatusSelected.length > 0)
                objCondition.push("skillStatusSelected=" + this.skillStatusSelected.join(","));
            if (this.statusSelected.length > 0)
                objCondition.push("statusSelected=" + this.statusSelected.join(","));
            if (this.reportSelected !== "")
                objCondition.push("reportSelected=" + this.reportSelected);
             if (this.orderName.length > 0) {
                    objCondition.push("orderName=" + this.orderName);
                    let type= this.orderType ? 'asc' : 'desc';
                    objCondition.push("orderType=" + type);
                }
            link.setAttribute('href', url + objCondition.join('&'));
            link.setAttribute('download', 'user.xlsx');
            link.click();
        },

        updateOrder(name) {

            if(this.orderName != name)
            this.orderType = false;
            this.orderName = name;
            this.orderType = !this.orderType;
            this.searchButtonStatus = true;

            this.fetchDataWithPaginationOrCondition();
        },

        resetInput() {
            this.nameSearch = "";
            this.areaSearch = "";
            this.departmentSelected = -1;
            this.skillListSelected = [];
            this.skillStatusSelected = [];
            this.statusSelected = [];
            this.reportSelected = "";
            this.orderName = "";
        }
    },
})
</script>

<style lang="css" scoped>
.input-group-text,
th {
  width: 200px;
}

.search {
  width: initial;
}

.btn-group .btn {
  margin-right: 10px;
}
</style>
