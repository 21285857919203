<template>
    <div>
        <div class="text-center" v-if="!loaded">
            <b-spinner variant="primary"></b-spinner>
        </div>

        <div style="display: inline-flex;">
            <router-link :to="{path: urlDetail, query: {users: user_seleteced, orderName : orderName , orderType : orderType ? 'asc' : 'desc', page: 'id-card'}}">
                <b-button variant="outline-success" class="mr-1" :disabled="user_seleteced.length == 0">
                確認
                </b-button>
            </router-link>
            <div>
                <span style="line-height: 38px;">
                    まとめて認証ができます（最大10件）
                </span>
            </div>
        </div>
        
         <!-- Status checkboxs -->
        <b-row class="mt-2 pl-3">
            <b-col md="2" class="input-group-text no-border-radius-right">
                ステータス
            </b-col>
            <b-col md="10" class="p-0 no-border-radius-left">
                <b-form-checkbox-group 
                    v-model="statusSelected" 
                    :options="id_status" 
                    class="mt-2 ml-2" 
                    value-field="item" 
                    text-field="name" 
                    disabled-field="notEnabled"
                ></b-form-checkbox-group>
            </b-col>
        </b-row>
        <!-- End status checkboxs -->
       
        <b-row class="mt-2">
            <b-col md="2">
                <!-- Page limit -->
                <div class="wrapper mb-4">
                    <b-form-select 
                        v-model="paginationSelected" 
                        @change="onChangePagination" 
                        class="selectBoxTag bs__select--size" 
                        :options="paginationSelectBox"
                    ></b-form-select>
                </div>
                <!-- End page limit -->
            </b-col>
            
            <!-- <b-col md="10 text-left">
                <paginate name="paginateIdCard" v-if="renderPaginateComponent" :page-count="totalPage" :click-handler="clickCallbackPagination" :prev-text="'<'" :next-text="'>'" :container-class="'pagination m-0 mr-3'"> </paginate>
            </b-col> -->

        </b-row>

        <!-- List user table -->
        <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="userChossed"
            name="flavour-2"
        >
        
        <b-table
            striped
            :items="this.data"
            :fields="fields"
            select-mode="multi"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            selectable
            ref="selectableTable"
            stacked="md"
            small
            class="mt-2 table-bordered text-center"
            @row-selected="onRowSelected"
            >
            <template #head(selected)="data">
                <div class="d-flex text-center justify-content-center">
                    {{ data.label }} 
                    <b-form-checkbox
                        :id="'checkbox-all'"
                        v-model="chosseAll"
                        name="checkbox-1"
                        class="ml-1"
                        value="-1"
                        unchecked-value="0"
                        @change="checkAll()"
                    >
                    </b-form-checkbox>
                </div>
            </template>

            <template #head(created_at)="data">
                {{ data.label }} 
                <b-button variant="outline-primary" size="sm" v-on:click="updateOrder('created_at')">
                    <i class="fas fa-sort"></i>
                </b-button>
            </template>
        
            <template #head(username)="data">
                {{ data.label }} 
                <b-button variant="outline-primary" size="sm" v-on:click="updateOrder('username')">
                    <i class="fas fa-sort"></i>
                </b-button>
            </template>
            
            <template #head(ID_validate)="data">
                {{ data.label }} 
                <!-- <b-button variant="outline-primary" size="sm" v-on:click="updateOrder('ID_validate')">
                    <i class="fas fa-sort"></i>
                </b-button> -->
                <!-- <b-button variant="outline-primary" size="sm" v-on:click="updateOrder('ID_validate')">
                    <i class="fas fa-sort"></i>
                </b-button> -->
            </template>
            
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <font-awesome-icon :icon="['far', 'check-square']" />
                </template>

                <template v-else>
                  <font-awesome-icon :icon="['far', 'square']" />
                </template>
            </template>
            
            <template #cell(created_at)="row">
                {{ formatDate(row.value) }}
            </template>


            <template #cell(ID_validate)="row">
                {{ statusText(row.value) }}
            </template>

            <template #cell(_id)="row">
                <router-link :to="{path: urlDetail, query: {users: [row.value], page: 'id-card'}}">
                    <b-button variant="outline-success" class="mr-1">
                    確認
                    </b-button>
                </router-link>
            </template>

            <template #row-details="row">
                <b-card>
                <ul>
                    <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                </ul>
                </b-card>
            </template>
        </b-table>
        </b-form-checkbox-group>
        <!-- End List user table -->
        
        <!-- <div class="paginationBLock">
            <paginate v-if="renderPaginateComponent" :page-count="totalPage" :click-handler="clickCallbackPagination" :prev-text="'<'" :next-text="'>'" :container-class="'pagination pb-3'"> </paginate>
        </div> -->
    
    </div>
</template>

<script lang="js">

import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import adminMixin from "../../../mixin/AdminMixin";
import { PAGE_SIZE } from "../../../common/config";
export default Vue.extend({
    name: "IdCard",
    data() {
        return {
            urlDetail : "/admin/validation/id-card/detail",
            order : null,
            totalRows : 0,
            loaded: false,
            chosseAll: [],
            selected: [], // Must be an array reference!
            buttons: [
                { caption: 'Toggle 1', state: true },
                { caption: 'Toggle 2', state: false },
                { caption: 'Toggle 3', state: true },
                { caption: 'Toggle 4', state: false }
            ],
            id_status: [
                { item: '0', name: '認証待ち' },
                { item: '1', name: '認証済' },
                { item: '-1', name: '未提出' },
                { item: '2', name: '未認証' }
            ],
            user_status: [
                { item: 'A', name: '有効' },
                { item: 'B', name: '無効' },
                { item: 'C', name: '退会' }
            ],
            user_report: [
                { item: 'A', name: 'あり' },
                { item: 'B', name: 'なし' }
            ],
            options: [
                { index: 0, value: null, text: 'Please select an option' },
                { index: 1, value: 'a', text: 'This is First option' },
                { index: 2, value: 'b', text: 'Selected Option' },
                { index: 3, value: { C: '3PO' }, text: 'This is an option with object value' },
                { index: 4, value: 'd', text: 'This one is disabled', disabled: true }
            ],
            userChossed:  [],
            fields: [
                { key: 'selected', label: '選択', class:'w-10'},
                { key: 'created_at', label: '提出日', class:'w-20'},
                { key: 'username', label: 'ユーザーネーム', class: 'text-center w-40' },
                { key: 'ID_validate', label: 'ステータス', class:'w-20'},
                { key: '_id', label: '確認', class:'w-10'}
            ],
            data : [],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            renderPaginateComponent: true,
            perPage: PAGE_SIZE,
            pageCurrent: 1,
            currentPage: 1,
            totalPage: 0,
            orderName: '',
            orderType: false,
            paginationSelected: 0,
            paginationSelectBox: [{
                    value: 0,
                    text: "表示件数切り替え",
                    disabled: true,
                },
                {
                    value: 20,
                    text: "20",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                }
            ],
            statusSelected: []
        };
    },
    mixins: [adminMixin],
    computed: {
        ...mapGetters([
            'getTokens',
        ]),
        user_seleteced () {
            let users = [];
            for(let item of this.selected) {
                users.push(item._id);
            }
            return users;
        },
    },
    methods: {
        ...mapActions(['handleCrudAPIAdmin']),
        
        statusText(status) {
            switch (status) {
                case 0:
                    return "認証待ち";
                case 1:
                    return "認証済";
                case 2:
                    return "未提出"
                default:
                    return "未認証";
            }
        },
        
        onRowSelected(items) {
            var newItem = items.filter(x => !this.selected.includes(x));
            if(items.length > 10)
                this.removeLastSelection(newItem);
            else
                this.selected = items;
            this.chosseAll.pop();
        },
        
        removeLastSelection(items) {
            (this.$refs.selectableTable).unselectRow(this.data.indexOf(items[items.length - 1]));
        },
        
        async checkAll() {
            if(this.chosseAll.length > 0) {
                let count = 0;
                for(let item of this.selected)
                    this.removeLastSelection([item]);
                for(let index in this.data) {
                    //Chuc nang xin xo
                    // if(this.data[index].ID_validate == 0) {
                    //     await (this.$refs.selectableTable).selectRow(parseInt(index));
                    //     this.chosseAll.push(-1);
                    //     count++;
                    //     if(count == 5)
                    //         return
                    // }
                    await (this.$refs.selectableTable).selectRow(parseInt(index));
                    this.chosseAll.push(-1);
                    count++;
                    if(count == 10)
                        return
                }
            }
            else {
                (this.$refs.selectableTable).clearSelected();
            }
        },
        
        async fetchData(objCondition = {}) {
            objCondition.size = this.perPage;
            objCondition.page = this.pageCurrent;
            if(this.orderName.length > 0) {
                objCondition.orderName = this.orderName;
                objCondition.orderType = this.orderType ? 'asc' : 'desc';
            }
            if(this.statusSelected.length > 0) {
                objCondition.idStatus = this.statusSelected;
            }
            let objPagram = {
                method: "post",
                urlAPi: "validate-ids",
                objCondition: objCondition
            };
            let dataResult = await this.handleCrudAPIAdmin(objPagram);
            this.data = dataResult.data.data;
            this.totalPage = dataResult.data.last_page;
            this.totalPage = dataResult.data.last_page;
            this.loaded = true;
        },
        
        async clickCallbackPagination(pageNumber) {
            this.pageCurrent = pageNumber;
            this.loaded = false;
            await this.fetchData();
        },
        
        updateOrder(name) {
            if(this.orderName != name)
                this.orderType = false;
            this.orderName = name;
            this.orderType = !this.orderType;
            this.fetchData();
        },
        
        async onChangePagination() {
            this.perPage = this.paginationSelected;
            await this.fetchData();
        },
    },
    async created() {
        await this.fetchData();
    },
    watch: {
        async statusSelected() {
            await this.fetchData();
        }
    }
})
</script>

<style lang="css" scoped>
    .input-group-text, th {
    width: 200px;
    }

    .search {
        width: initial;
    }

    .btn-group .btn {
        margin-right: 10px;
    }

    tr td:nth-child(3 + n) {
        width: 20%;
    }

    tr td:nth-child(1), tr td:nth-child(5) {
        width: 10%;
    }

    tr td:nth-child(2) {
        width: 40%;
    }
</style>
