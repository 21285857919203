<template>
  <div>
    <b-alert
      variant="success"
      show
      v-if="$route.params.success_remove == '1' || deleted_success"
      >削除しました</b-alert
    >
    <div>
      <b-card no-body>
        <b-tabs pills card vertical>
          <b-tab v-for="user in users" :key="user.id" :title="user.username">
            <b-container class="mt-2" fluid>
              <b-row class="mt-2" v-if="user.get_id_images != null">
                <b-col md="12" class="input-group-text">身分証承認</b-col>
              </b-row>

              <b-row class="mt-2" v-if="user.get_id_images != null">
                <b-col md="10">
                  <b-row>
                    <b-col md="6" class="border-secondary">
                      <b-row>
                        <b-col md="4">
                          <span style="font-size: 0.8rem">ユーザーネーム</span>
                        </b-col>

                        <b-col md="8">
                          <router-link
                            :to="{
                              name: 'admin-users-detail',
                              query: { users: user._id },
                            }"
                          >
                            {{ user.username }}
                          </router-link>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="4">
                          <span style="font-size: 0.8rem">ステータス</span>
                        </b-col>

                        <b-col md="8">
                          <b-form-group
                            v-slot="{ ariaDescribedby }"
                            v-if="user.ID_validate == 0"
                          >
                            <b-form-radio-group
                              :id="'radio-validate-id-card-' + user._id"
                              v-model="current_id_validation"
                              :options="options"
                              :aria-describedby="ariaDescribedby"
                              :name="'id-card-' + user._id"
                            ></b-form-radio-group>
                          </b-form-group>

                          <span v-if="user.ID_validate != 0">{{
                            user.ID_validate == 1 ? "認証済" : "認証しない"
                          }}</span>
                          <b-form-radio
                            v-model="IDselected"
                            name="some-radios"
                            class="d-inline ml-3"
                            value="true"
                            v-if="user.ID_validate != 0"
                          >
                            {{
                              user.ID_validate == 1 ? "認証しない" : "認証する"
                            }}
                          </b-form-radio>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="4">
                          <span style="font-size: 0.8rem">画像</span>
                        </b-col>

                        <b-col md="8">
                          <b-img
                            v-if="user.get_id_images.length > 0"
                            thumbnail
                            fluid
                            v-bind:src="mapAWS_URL(user.get_id_images[0].path)"
                            v-on:click="zoomImage(user.get_id_images[0].path)"
                            alt="Image 1"
                          ></b-img>
                          <b-img
                            class="mb-2"
                            v-if="user.get_id_images.length == 0"
                            thumbnail
                            fluid
                            v-bind:src="'/f_admin/upload/no-image.png'"
                            alt="Image 1"
                          ></b-img>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="12" class="text-right mt-2">
                          <b-button
                            href="#"
                            variant="outline-secondary"
                            v-on:click="changeIDValidate(user)"
                            >更新</b-button
                          >
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- End ID Card validation -->

              <b-row class="mt-2" v-if="user.get_id_images != null">
                <b-col md="12" class="text-center">
                  <router-link
                    :to="{
                      name:
                        $route.query.page == 'id-card'
                          ? 'admin-validation-id-card'
                          : 'admin-validation-skill',
                    }"
                  >
                    <b-button variant="outline-secondary">一覧に戻る</b-button>
                  </router-link>
                </b-col>
              </b-row>

              <!-- Skill validation -->
              <b-row class="mt-4">
                <b-col md="12" class="input-group-text"
                  >ユーザースキル/資格一覧</b-col
                >
              </b-row>
              <b-row class="mt-4">
                <div style="display: inline-flex;">
                    <b-button
                      variant="outline-danger"
                      class="mr-1"
                      :disabled="user.get_certificates.length == 0"
                      v-on:click="deleteSkill()"
                    >
                      確認
                    </b-button>
                  <div>
                  <span style="line-height: 38px;">
                    まとめてスキル/資格を削除ができます（最大10件）
                  </span>
                  </div>
                </div>
              </b-row>
              <b-row class="mt-4">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="col-1 text-center" scope="col">
                        選択
                        <input
                          type="checkbox"
                          v-model="chosseAll"
                          value="true"
                          @click="checkall"
                        />
                      </th>
                      <th class="text-center" scope="col">申請日</th>
                      <th class="text-center" scope="col">ユーザーネーム</th>
                      <th class="text-center" scope="col">スキル/資格</th>
                      <th class="col-2 text-center" scope="col">削除</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="skill in user.get_certificates" :key="skill.id">
                      <td class="col-1 text-center">
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            v-model="skill.check"
                            :value="skill.id"
                            :checked="chosseAll"
                          />
                          <i class="form-icon"></i>
                        </label>
                      </td>
                      <td class="text-center">
                        {{
                          new Date(skill.created_at).toISOString().split("T")[0]
                        }}
                      </td>
                      <td class="text-center">{{ user.username }}</td>
                      <td class="text-center">{{ skill.get_skill.name }}</td>
                      <td class="col-2 text-center">
                        <b-button
                          variant="outline-danger"
                          v-on:click="deleteSkill(skill.id)"
                        >
                          削除
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <b-modal
      id="bv-image-modal"
      v-model="modalShow"
      size="lg"
      :hide-header="true"
      :hide-footer="true"
    >
      <b-img
        class="w-100"
        thumbnail
        fluid
        v-bind:src="mapAWS_URL(zoomImageSrc)"
      ></b-img>
    </b-modal>

    <b-modal
      v-model="modalIDStatusShow"
      id="update-status-model"
      @ok="updateIDStatus()"
      cancel-title="キャンセル"
      >ステータスを更新しますか</b-modal
    >
    <b-modal
      v-model="modalSkillStatusShow"
      id="update-status-model"
      @ok="skillValidate()"
      cancel-title="キャンセル"
      >ステータスを更新しますか</b-modal
    >
  </div>
</template>

<script lang="js">
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { AWS_URL } from "../../../common/config";
export default Vue.extend({
    data() {
        return {
            urlDelete : "/admin/validation-skills/delete",
            remarks: '',
            value: '',
            selected: 0,
            options: [
                { text: '認証する', value: 1 },
                { text: '認証しない', value: 2 },
            ],
            skills: [],
            birthday: '',
            users: [],
            image: 'https://picsum.photos/250/250/?image=54',
            data: [],
            loaded: false,
            zoomImageSrc: '',
            modalShow: false,
            modalStatusShow: false,
            modalIDStatusShow: false,
            modalSkillStatusShow: false,
            current_user: {},
            IDselected: false,
            current_id_validation: 0,
            current_skill_id: '',
            current_skill_status: 0,
            deleteId: '',
            updated_success: true,
            deleted_success: false,
            chosseAll: false,
        };
    },
    computed: {
        ...mapGetters([
            'getTokens',
        ]),
    },
    methods: {
        ...mapActions(['handleCrudAPIAdmin']),
        async fetchData(objCondition = {}) {
            let users = this.$route.query.users;
            let orderName = this.$route.query.orderName;
            let orderType = this.$route.query.orderType;
            objCondition.users = typeof users == "string" ? [users] : users;
            objCondition.orderName = orderName;
            objCondition.orderType = orderType;
            let objPagram = {
                method: "post",
                urlAPi: "validate/detail",
                objCondition: objCondition
            };
            let dataResult = await this.handleCrudAPIAdmin(objPagram);
            this.users = dataResult.data;
            this.users = this.users.map(user => ({
                ...user,
                ID_validate_original : user.ID_validate,
                get_certificates: user.get_certificates.map(skill => ({
                    ...skill,
                    old_status: skill.status
                }))
            }))
            this.loaded = true;
            this.chosseAll = false;
        },
        async updateStatus(objCondition = {}) {
            let objPagram = {
                method: "put",
                urlAPi: "validate/detail",
                objCondition: objCondition
            };
            await this.handleCrudAPIAdmin(objPagram);
            this.loaded = true;
            this.modalStatusShow = true;
        },
        updateDeleteId(id) {
            this.deleteId = id;
        },

        deleteItem() {
            this.loaded = false;

            let objCondition = {
            _id: this.deleteId
            };
            let objParam = {
            action: "delete",
            method: "post",
            urlAPi: "admin-post/delete",
            objCondition: objCondition,
            };
            this.handleCrudAPIAdmin(objParam).then(data => {
            if (data.ok == true) {
                this.fetchData()
                this.updated_success = false;
                this.deleted_success = true;
            }
            });
        },

        deleteSkill(val = null) {
          this.error2 = [];
          if(!confirm("削除しますか ?")){
              return;
          }

          let arr = [];
          if(val==null) {
            this.users[0].get_certificates.forEach((item) => {
              if(item.check==true) {
                  arr.push(item);
              }
            });
          } else{
              arr.push(val);
          }

          if(arr.length<1){
              alert("チェックボックスにチェックを入れてください"); return;
          }

          let objCondition = {};
          objCondition.data = arr;
          let objParam = {
            action: "delete",
            method: "post",
            urlAPi: "validation-skills/delete",
            objCondition: objCondition
          };

          this.handleCrudAPIAdmin(objParam).then(data=>{

            if(data.code == 422)
                this.errors = data.error;

            else
                // this.$refs['my-modal'].hide();
                // this.creat();
                //return this.$router.push({ name: 'admin-video', params:{success:"1"}});
                this.fetchData();
                this.showDismissibleAlert=true;
          });
        },

        idCardValidate: function(id, status) {
            let data = {
                _id : id,
                status : status,
                type : 0
            };
            this.loaded = false;
            this.updateStatus(data);
            this.current_user.ID_validate = status;
            for(let user of this.users) {
                if(user._id == this.current_user._id) {
                    user.status = status;
                    user.ID_validate_original = status;
                }
            }
        },
        skillValidate: function() {
            let data = {
                _id : this.current_skill_id,
                status : this.current_skill_status,
                type : 1
            };
            this.loaded = false;
            this.updateStatus(data);
            for(let user of this.users) {
                if(user._id == this.current_user._id) {
                    for(let skill of user.get_certificates)
                        if(skill._id == this.current_skill_id)
                            skill.old_status = this.current_skill_status;
                }
            }
        },
        zoomImage(path) {
            this.zoomImageSrc = path;
            this.modalShow = true;
        },

        mapAWS_URL: function(url) {
            return AWS_URL + url;
        },
        changeIDValidate(user) {
            this.current_user = user;
            this.modalIDStatusShow = true;
        },
        updateIDStatus() {
            let status = this.current_id_validation;
            if(this.IDselected)
                status = this.current_user.ID_validate == 1 ? 2 : 1;
            this.idCardValidate( this.current_user._id,  status);
            this.IDselected = false;
        },

        checkall(){
          var val = false;
          if(this.chosseAll) { val = false; } else { val = true; }
          this.users[0].get_certificates.forEach((item) => {
              item.check = val;
            });
        },
    },
    async created() {
        await this.fetchData();
    },
})
</script>

<style lang="css" scoped>
.input-group-text,
th {
  width: 200px;
}

.search {
  width: initial;
}

.btn-group .btn {
  margin-right: 10px;
}

.card-img-top {
  height: 200px;
}

.btn-submit {
  width: 200px;
}
</style>
