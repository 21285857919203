<template>
    <div>
        <div class="text-center" v-if="!loaded">
            <b-spinner variant="primary"></b-spinner>
        </div>

        <b-row class="mt-2">
            <b-col md="2" style="p-1">
                <!-- Page limit -->
                <div class="wrapper mb-4">
                    <b-form-select 
                        v-model="paginationSelected" 
                        @change="onChangePagination" 
                        class="selectBoxTag bs__select--size" 
                        :options="paginationSelectBox"
                    ></b-form-select>
                </div>
                <!-- End page limit -->
            </b-col>
            <b-col md="10 text-left">
                <paginate v-if="renderPaginateComponent" :page-count="totalPage" :click-handler="clickCallbackPagination" :prev-text="'<'" :next-text="'>'" :container-class="'pagination m-0 mr-3'"> </paginate>
            </b-col>
        </b-row>
        
        <!-- List user table -->
        <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="user_chossed"
            name="flavour-2"
        >

        <b-table
            striped
            :items="this.data"
            :fields="fields"
            select-mode="multi"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            selectable
            ref="selectableTable"
            stacked="md"
            small
            class="mt-2 table-bordered text-center"
            @row-selected="onRowSelected"
            >
            
            <col
                v-for="field in fields"
                :key="field.key"
                :style="{ width: field.width }"
            >

            <template #head(created_at)="data">
                {{ data.label }} 
                <b-button variant="outline-primary" size="sm" v-on:click="updateOrder('created_at')">
                    <i class="fas fa-sort"></i>
                </b-button>
            </template>
        
            <template #head(username)="data">
                {{ data.label }} 
                <b-button variant="outline-primary" size="sm" v-on:click="updateOrder('username')">
                    <i class="fas fa-sort"></i>
                </b-button>
            </template>
            
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <font-awesome-icon :icon="['far', 'check-square']" />
                </template>

                <template v-else>
                  <font-awesome-icon :icon="['far', 'square']" />
                </template>
            </template>
            
            <template #cell(created_at)="row">
                {{ formatDate(row.value) }}
            </template>

            <template #cell(get_certificates)="row">
                <p v-for="(skill, index) in getUserSkillName(row.item.get_certificates)" :key="index">
                    {{ skill }}
                </p>
            </template>

            <template #cell(status)="row">
                <p v-for="(status, index) in getStatuses(row.item.get_certificates)" :key="index">
                    {{ status }}
                </p>
            </template>

            <template #cell(_id)="row">
                <router-link :to="{path: url_detail, query: {users: [row.value], page: 'skills'}}">
                    <b-button variant="outline-success" class="mr-1">
                    確認
                    </b-button>
                </router-link>
            </template>

            <template #row-details="row">
                <b-card>
                <ul>
                    <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                </ul>
                </b-card>
            </template>
        </b-table>
        </b-form-checkbox-group>
        <!-- End List user table -->
        
        <div class="paginationBLock mb-3">
            <paginate v-if="renderPaginateComponent" :page-count="totalPage" :click-handler="clickCallbackPagination" :prev-text="'<'" :next-text="'>'" :container-class="'pagination pb-3'"> </paginate>
        </div>
    
    </div>
</template>

<script lang="js">

import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import adminMixin from "../../../mixin/AdminMixin";
import { PAGE_SIZE } from "../../../common/config";
export default Vue.extend({
    data() {
        return {
            url_detail : "/admin/validation/skills/detail",
            total_rows : 0,
            loaded: false,
            chosseAll: [],
            skill_selected: [],
            status_selected: [],
            report_selected: [],
            selected: [], // Must be an array reference!
            buttons: [
                { caption: 'Toggle 1', state: true },
                { caption: 'Toggle 2', state: false },
                { caption: 'Toggle 3', state: true },
                { caption: 'Toggle 4', state: false }
            ],
            user_status: [
                { item: 'A', name: '有効' },
                { item: 'B', name: '無効' },
                { item: 'C', name: '退会' }
            ],
            user_report: [
                { item: 'A', name: 'あり' },
                { item: 'B', name: 'なし' }
            ],
            options: [
                { index: 0, value: null, text: 'Please select an option' },
                { index: 1, value: 'a', text: 'This is First option' },
                { index: 2, value: 'b', text: 'Selected Option' },
                { index: 3, value: { C: '3PO' }, text: 'This is an option with object value' },
                { index: 4, value: 'd', text: 'This one is disabled', disabled: true }
            ],
            user_chossed:  [],
            fields: [
                { key: 'created_at', label: '申請日', class:'w-20'},
                { key: 'username', label: 'ユーザーネーム', class: 'text-center w-20'},
                { key: 'get_certificates', label: 'スキル/資格', class:'w-20' },
                { key: '_id', label: '確認', class:'w-10' }
            ],
            data : [],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            renderPaginateComponent: true,
            pageCurrent: 1,
            currentPage: 1,
            totalPage: 0,
            orderName: '',
            orderType: false,
            paginationSelected: 0,
            paginationSelectBox: [{
                    value: 0,
                    text: "表示件数切り替え",
                    disabled: true,
                },
                {
                    value: 20,
                    text: "20",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                }
            ],
            perPage: PAGE_SIZE,
            statusSelected: []
        };
    },
    mixins: [adminMixin],
    computed: {
        ...mapGetters([
            'getTokens',
        ]),
        
        user_seleteced () {
            let users = [];
            for(let item of this.selected) {
                users.push(item._id);
            }
            return users;
        },
    },
    methods: {
        ...mapActions(['handleCrudAPIAdmin']),
        getStatuses(certificates) {
            let nameStatus = [];
            let uniqueSkill = [];

            certificates.forEach(function (item) {
                if (!uniqueSkill.includes(item.skill_id)) {
                    uniqueSkill.push(item.skill_id);
                    switch(item.status) {
                        case 0:
                            nameStatus.push("認証待ち");
                            break;
                        case 1:
                            nameStatus.push("認証済");
                            break;
                        case 2:
                            nameStatus.push("未認証");
                            break;
                        default:
                            nameStatus.push("未認証");
                            break;
                    }
                }
            });

            return nameStatus;
        },
        
        onRowSelected(items) {
            var newItem = items.filter(x => !this.selected.includes(x));
            if(items.length > 10)
                this.removeLastSelection(newItem);
            else
                this.selected = items;
            this.chosseAll.pop();
        },
        
        removeLastSelection(items) {
            (this.$refs.selectableTable).unselectRow(this.data.indexOf(items[items.length - 1]));
        },
        
        async fetchData(objCondition = {}) {
            objCondition.size = this.perPage;
            objCondition.page = this.pageCurrent;
            if(this.orderName.length > 0) {
                objCondition.orderName = this.orderName;
                objCondition.orderType = this.orderType ? 'asc' : 'desc';
            }
            if(this.statusSelected.length > 0) {
                objCondition.skillStatusSelected = this.statusSelected;
            }
            let objPagram = {
                method: "post",
                urlAPi: "validation-skills",
                objCondition: objCondition
            };
            let dataResult = await this.handleCrudAPIAdmin(objPagram);
            this.data = dataResult.data.data;
            this.totalPage = dataResult.data.last_page;
            this.totalPage = dataResult.data.last_page;
            this.loaded = true;
        },
        
        async clickCallbackPagination(pageNumber) {
            this.pageCurrent = pageNumber;
            this.loaded = false;
            await this.fetchData();
        },
        
         getUserSkillName(certificates) {
            let nameSkill = [];
            let uniqueSkill = [];

            certificates.forEach(function (item) {
                if (!uniqueSkill.includes(item.skill_id)) {
                    nameSkill.push(item.get_skill.name);
                    uniqueSkill.push(item.skill_id);
                }
            });

            return nameSkill;
        },
        
        updateOrder(name) {
            if(this.orderName != name)
                this.orderType = false;
            this.orderName = name;
            this.orderType = !this.orderType;
            this.fetchData();
        },
        
        async onChangePagination() {
            this.perPage = this.paginationSelected;
            await this.fetchData();
        },
    },
    async created() {
        await this.fetchData();
    },
    
    watch: {
        async statusSelected() {
            await this.fetchData();
        }
    }
})
</script>

<style lang="css" scoped>
    .input-group-text, th {
    width: 200px;
    }

    .search {
        width: initial;
    }

    .btn-group .btn {
        margin-right: 10px;
    }

    tr td:nth-child(3 + n) {
        width: 20%;
    }

    tr td:nth-child(1), tr td:nth-child(5) {
        width: 10%;
    }

    tr td:nth-child(2) {
        width: 40%;
    }
</style>
