<template>
  <div>
    <p>
      「エフコネ利用規約」（以下「本規約」といいます）は、クリエイティブフォロー株式会社（以下「当社」といいます）が提供する情報サービス「エフコネ」の利用条件を定めるものです。 <br>
      本サービスは、専用アプリケーションである「エフコネ」をダウンロードすることで、情報配信会社（以下「情報サプライヤー」といいます）又は当社、会員様が提供するニュース、情報、動画等（以下、併せて「コンテンツ」といいます）を閲覧すること）等が可能です。 <br>
      本規約において、「ユーザー」とは、本サービスの利用者をいい、本サービスを利用した時点から、本規約及び個人情報保護方針に同意したものとみなします。 <br>
    </p>

    <div>
       <p  class="title"> 第1条（適用の範囲）</p>
      <span> 本規約は、当社が運営する本サービスに関する一切について適用されるものとします。</span>
    </div>

    <div>
       <p  class="title"> 第2条（必要環境）</p>
      <span> 本アプリのダウンロード、又は本サービスを通じた第三者のサービス・コンテンツ・リソースの利用には、インターネットへの接続が必要になります。いずれも通信料金はユーザーの負担となります。</span>
    </div>

    <div>
       <p  class="title"> 第3条（本サービスの利用）</p>
      <span> 
        1.  本サービスは、本規約のほか、適用される法令等を遵守している者に限り、利用することができるものとします。 <br> 
        2.  ユーザーの担当者がアカウントを作成し、各ユーザーが会員登録する事で、本サービスを会員として利用することができるようになります。 <br>
        3.  本サービスは個人で利用するものとし、法人での利用やアカウント作成については別途、当社への申請と許諾を必要とします。 <br>
        4.  当社は、ユーザーが以下の各号に該当すると判断した場合、事前の通知・催告等を経ることなく、アカウントの利用制限や削除、また本サービスへのアクセスを恒久的又は一時的に停止したり、削除したりすることができるものとします。 <br>
        5.  本規約に違反した場合。 <br>
        6.  死亡した場合。 <br>
        7.  過去に本サービスの利用を停止され、又は会員登録を取り消されたことがある場合。 <br>
        8.  暴力団、暴力団員、暴力団準構成員、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これに準じる反社会的勢力（以下「反社会的勢力等」といいます）であると判明した場合、又は資金提供その他の行為を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与をしている場合。 <br>
        9.  上記各号の他、当社がアカウントの停止及びユーザーコンテンツの削除等が適切であると判断した場合。 <br>
        10. ユーザーは本サービスに会員登録するにあたり、以下の点を遵守するものとします。 <br>
        11. アカウントが当社によって停止された場合、当社の許可なく新たなアカウントを作成することはできません。 <br>
        12. アカウントの連絡先情報を、当社からのメールを必ず受け取ることができる状態に保つものとします。 <br>
        13. パスワードを共有したり、他人にアカウントへのアクセスを許可したり、その他、アカウントのセキュリティを脅かす恐れのある行為を行わないものとします。 <br>
        14. アカウントを他人に譲渡することはできません。 <br>
        15. アカウントのパスワードなどは、自己の責任において大切に保管し、機密を保持するものとします。 <br>ユーザーのアカウントが権限のない者により利用された形跡に気づいた場合、ただちに当社に通知するものとします。 <br>
        16. 当社は、会員のアカウントが当該会員以外の第三者によって利用された場合でも、当該会員による利用とみなすことができるものとします。 <br>
        17. 当社では、ユーザーの皆さまによりよい機能やサービスを提供することができるよう、日々革新を続けています。 <br>その目的を達成するために本サービスは、事前の通知等を経ることなく、変更され、機能追加され、本サービス又はその機能の提供が中止されることがあり、また本サービスの利用に新たな制約が課されることがあります。 <br>
        18. 本アプリを利用するにあたり、ユーザーは、ソフトウェアの改善・向上のためにアップグレードやアップデート、追加機能のダウンロードが実行されることに同意します。 <br>
      </span>
    </div>

     <div>
       <p  class="title"> 第4条（本アプリ及び本サービスの変更・停止・終了）</p>
       <span>  
          当社は、ユーザーへ事前に通知することなく、本アプリ及び本サイト及び本サービスを変更、停止、終了すること、ならびにコンテンツ等を削除することができます。
       </span>
     </div>

      <div>
       <p  class="title"> 第5条（著作権等）</p>
       <span>  
          1.  本サービスに関わる著作権、商標権その他の一切の知的財産権及びその他の財産権はすべて、当社に帰属します。 <br>
          2.  コンテンツに関わる著作権、商標権その他の一切の知的財産権及び財産権は、当社又は正当な権利を有する情報サプライヤーに帰属します。 <br>
          3.  ユ－ザーコンテンツに関わる著作権、商標権その他の一切の知的財産権及びその他の財産権は、すべてユーザーコンテンツを投稿したユーザー又は当該ユーザーコンテンツの著作権がユーザー以外の第三者に帰属する場合には当該著作権者に帰属します。 <br>
          4.  ユーザーは、当社及び当社から権利を承継し又は許諾された者に対して、ユーザーコンテンツの国内外における複製、公衆送信、頒布、翻訳・翻案等、著作権法上の権利（当社から第三者に対する再使用許諾権を含みます。）を、当該著作権の存続期間の満了日まで、無償で利用することを許諾したものとします。また、ユーザーは当社及び当社から権利を承継し又は許諾された者に対して著作者人格権を行使しないものとします。 <br>
       </span>
     </div>

      <div>
       <p  class="title"> 第6条（禁止事項）</p>
       <span>  

            ユーザーは、本サービスの利用にあたり、以下の行為を自ら又は第三者を通じて行わないものとします。 <br>
            <br>
            ① 本アプリ、コンテンツを、著作権法で認められた私的利用の範囲を超えて、複製、出版、公表、譲渡、公衆送信、改変その他の態様で利用する行為。 <br>
            ② 自動化された手段(情報収集ボット、ロボット、スパイダー、スクレーパーなど)を使用して、本サービスにアカウント登録したり、アクセスしたりする行為。 <br>
            ③ 当社又は正当な権利を有する権利者の著作権、登録商標等の知的所有権を侵害する行為、又は侵害する恐れのある行為。 <br>
            ④ 当社の通信設備、コンピューター、その他の機器及びソフトウェアに不正にアクセスし、又は、その利用若しくは運用に支障を与える行為又は与える恐れのある行為。 <br>
            ⑤ 当社の明示的な書面承諾なくして、本サービス中のいかなる個別部分若しくは資料、当社及び本サービスの名前、いかなる当社の商標、ロゴ又はコンテンツ、いかなるページ若しくはページに含まれるフォームのレイアウト及びデザインを使用、表示、ミラー、フレーム又はフレーム技術を使用しエンクローズする行為。 <br>
            ⑥ 商業目的などで不特定多数にコンテンツを閲覧させること又は不正な商用メッセージ(迷惑メールなど)を投稿する行為。 <br>
            ⑦ 本サービスで、ピラミッド商法など、非合法なマルチ商法などを実施する行為。 <br>
            ⑧ 本サービスで、特定の宗教団体や宗教活動へ勧誘する行為。 <br>
            ⑨ ウイルス又はその他の悪質なコードをアップロードする行為。 <br>
            ⑩ 他のユーザーのログイン情報を求め、他人のアカウントにアクセスする行為。 <br>
            ⑪ 当社のソースコードの改変や派生物の作成、デコンパイル、その他の操作行為。 <br>
            ⑫ 非合法な行為、誤解を招く行為、悪質な行為、差別的な行為。 <br>
            ⑬ コンテンツを無断で転載・利用する行為。 <br>
            ⑭ 上記各号のほか、法令に違反する行為、本規約の違反を助長又は推奨する行為、本サービスの運営を妨害する行為、当社の信用を毀損し、若しくは当社の財産を侵害する行為、当社に不利益を与える行為、その他当社が本サービスの利用目的から鑑みて不適切と判断する行為。 <br>
       </span>

      <div>
       <p  class="title"> 第7条（個人情報の保護等）</p>
       <span>  
          1.  当社は、当社が取り扱う個人情報を、当社の定める個人情報保護方針（以下「当社個人情報保護方針」といいます。）に則り大切に取扱います。 <br>
          2.  ユーザーは、当社個人情報保護方針を確認の上、これに記載されるユーザー情報の第三者提供につき同意するものとします。 <br>
          3.  仮に担当者がその所属する会社（以下「担当会社」といいます。）を退職するなどして交代することになった場合、本サービスの利用契約は当社と担当会社間で締結されていること、および業務の引継ぎを円滑に行うため、新たに担当者となった者が、前担当者が利用していた本サービスを引き継いで利用し、その結果ユーザーが入力した情報等も引き継ぐことになることに予め同意するものとします。 <br>
       </span>
     </div>

     <div>
       <p  class="title"> 第8条（免責）</p>
       <span>  
          1.  当社は、ユーザーに対し、本サービスの一切の動作保証を行わず、いかなる責任も負いません。 <br>
          2.  本サービスの提供する情報の完全性、真実性、正確性、合法性等に当社は一切の責任を負いません。 <br>
          3.  当社は、本サービス及びコンテンツ並びにユーザーコンテンツが第三者の知的財産権その他の権利を侵害していないことを保証するものではありません。 <br>
          4.  当社は、天災地変その他不可抗力（回線の輻輳、回線の障害、サーバーダウン等を含みますが、これらに限られません。）により生じた損失（本サービス上の情報等の喪失を含む。）につき、何らの責任も負わないものとします。 <br>
          5.  担当者とユーザーとのチャット間におけるトラブルについて、当社は責任を負いません。 <br>
       </span>
     </div>


      <div>
       <p  class="title"> 第9条（会員からの退会手続）</p>
       <span>  
          1.  会員が退会を希望する場合は、当社所定の退会手続を行うものとします。 <br>
          2.  会員が退会手続を完了した場合、又は当社と会員との間の本規約に基づく契約が終了した場合であっても、第6条から第12条については、なお有効とします。 <br>
       </span>
     </div>

      <div>
       <p  class="title"> 第10条（本規約の変更）</p>
       <span>  
          1.  当社は、一定の猶予期間を設けて変更後の本規約の効力発生日を定め、かつ、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生日を事前に本アプリ上への掲載、その他当社が適当と判断する方法で周知する方法により本規約の変更を行うことができるものとします。 <br>
          2.  当社は、変更後の本規約を本アプリ上で公開します。本規約変更後、ユーザーが本サービスを利用し又は前項に定める当社所定の期間が経過した場合には、ユーザーは変更後の利用規約に同意したものとみなします。 <br>
       </span>
     </div>

    <div>
       <p  class="title"> 第11条（準拠法）</p>
       <span>  
          本規約は、日本法に準拠し、日本法に基づき解釈されます。
       </span>
     </div>

    <div>
       <p  class="title"> 
            第12条（専属的合意管轄裁判所）
          </p>
       <span>  
          本規約に関する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
       </span>

     </div>

     <div class="title">

附則
2021年2月1日制定
     </div>
  </div>
  </div>
</template>


<script lang="js">
  import Vue from 'vue'
  export default Vue.extend({
    data() {
      return {
        
      };
    },
  })
</script>
