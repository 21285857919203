<template>
    <div>
        <b-container class="mt-2" fluid>
            <b-alert show variant="danger" v-for="(error, index) in errors" :key="index">
              {{error[0]}}
            </b-alert>
            
            <!-- Date input -->
            <b-row class="mt-2">
                <b-col md="2" class="input-group-text no-border-radius-right">投稿年月日</b-col>
                <b-col md="10" class="p-0 no-border-radius-left">
                  <b-form-datepicker   
                    id="example-datepicker" 
                    v-model="post.created_at" 
                    class="p-0 no-border-radius-left" 
                    disabled locale="ja" placeholder=""
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  ></b-form-datepicker>
                </b-col>
            </b-row>
            <!-- End Date input -->

            <!-- Status input -->
            <b-row class="mt-2">
                <b-col md="2" class="input-group-text no-border-radius-right">ステータス</b-col>
                <b-col md="10" class="p-0 no-border-radius-left border-form">
                    <b-form-radio-group
                        class="mt-2 ml-2"
                        id="radio-group-1"
                        v-model="post.status"
                        :options="options"
                        name="radio-options"
                    ></b-form-radio-group>
                </b-col>
            </b-row>
            <!-- End Status input -->

            <!-- Author input -->
            <b-row class="mt-2">
                <b-col md="2" class="input-group-text no-border-radius-right">投稿者</b-col>
                <b-col md="10" class="p-0 no-border-radius-left">
                    <b-form-input class="no-border-radius-left" v-model="username.username" maxlength="40" disabled></b-form-input>
                </b-col>
            </b-row>
            <!-- End Author input -->

            <!-- Title input -->
            <!-- <b-row class="mt-2">
                <b-col md="2" class="input-group-text no-border-radius-right">タイトル</b-col>
                <b-col md="10" class="p-0 no-border-radius-left">

                    <b-form-input class="no-border-radius-left" v-model="post.title" maxlength="40" disabled></b-form-input>
                </b-col>
            </b-row> -->
            <!-- End Title input -->



            <!-- Content input -->
            <b-row class="mt-2">
                <b-col md="2" class="input-group-text no-border-radius-right">本文</b-col>
                <b-col md="10" class="p-0 no-border-radius-left">
                    <b-form-textarea
                        id="textarea"
                        v-model="post.content"
                        rows="3"
                        max-rows="6"
                        maxlength="10000"
                        disabled
                    ></b-form-textarea>
                </b-col>
            </b-row>
            <!-- End Content input -->

            <!-- List image -->
            <b-row class="mt-2">
                <b-col md="2" class="input-group-text no-border-radius-right">画像</b-col>
                <b-col md="10" class="border-form">
                    <b-row>
                      <b-col md="4" v-for="image in images" :key="image._id" class="border-secondary">
                          <b-img thumbnail fluid :src="mapAWS_URL(image.path)" width="200" alt="Image 1" v-on:click="zoomImage(image.path)"></b-img>
                      </b-col>
                    </b-row>

                </b-col>
            </b-row>
            <!-- End List image -->

            <!-- Repost history -->
            <b-row class="mt-2">
                <b-col md="2" class="input-group-text no-border-radius-right">通報履歴</b-col>
                <b-col md="10" class="border-form input-group-text no-border-radius-right" style="display: block; text-align: left">
                   <p v-for="report in reportHistory" :key="report._id">
                     {{formatDate(report.created_at)}} 
                     -
                     <span v-if="report.get_own">{{report.get_own.username}}</span>
                     -
                     {{report.content}}
                   </p>
                </b-col>
            </b-row>
            <!-- End Repost history -->

            <!--  Buttons -->
            <b-row class="mt-2 pb-3">
                <b-col md="12" class="text-right pl-0">
                  <b-button href="#" v-on:click="update" variant="outline-success" class="btn-submit">更新</b-button>

        
                  <b-button variant="outline-danger" v-on:click="updateDeleteId(post._id)" class="mr-1" v-b-modal.delete-model>
                  削除
                  </b-button>
                </b-col>
            </b-row>
            <!-- End Buttons -->
            <b-row class="mt-2">
              <b-col md="12" class="d-flex justify-content-center">
                <router-link to="/admin/user-post">
                    <b-button class="btn-back" variant="outline-secondary">一覧へ戻る</b-button>
                </router-link>
              </b-col>
            </b-row>
        </b-container>
        
        <b-modal id="delete-model" @ok="deleteItem" cancel-title="キャンセル">削除してもよろしいでしょうか。</b-modal>
        <b-modal 
            id="bv-image-modal" 
            v-model="modalShow" 
            size="lg" 
            :hide-header="true"
            :hide-footer="true"
        >
            <b-img 
                class="w-100"
                thumbnail fluid 
                v-bind:src="mapAWS_URL(zoomImageSrc)" 
            ></b-img>
        </b-modal>
    </div>
</template>

<script lang="js">
import Vue from 'vue'
import { mapActions,mapGetters } from 'vuex'
import adminMixin from "../../../mixin/AdminMixin";
import { AWS_URL } from "../../../common/config";
export default Vue.extend({
    data() {
        return {
          options: [
              { text: '表示', value: '1' },
              { text: '非表示', value: '0' },
          ],
          post: {},
          errors: [],
          reportHistory: [],
          images: [],
          username: {},
          deleteId: '',
          modalShow: false,
          zoomImageSrc: ""
        };
    },
    mixins: [adminMixin],

    computed: {
        ...mapGetters([
          'getTokens',
        ]),
    },

  methods: {
    ...mapActions(['handleCrudAPIAdmin']),
    
    mapAWS_URL: function(url) {
        return AWS_URL + url;
    },
    
    update() {
      let objCondition = {
        status: parseInt(this.post.status),
        _id: this.$route.query.id
      };
      let objParam = {
        action: "detail",
        method: "post",
        urlAPi: "user-post/update",
        objCondition: objCondition
      };
      this.handleCrudAPIAdmin(objParam).then(data=>{
        if(data.code == 422)
          this.errors = data.error;
        else
          return this.$router.push({ name: 'admin-user-post', params:{success:"1"}});
      });
    },
    
    updateDeleteId(id) {
      this.deleteId = id;  
    },
    
    deleteItem() {
        let objCondition = {
          _id: this.deleteId
        };
        let objParam = {
          action: "delete",
          method: "post",
          urlAPi: "user-post/delete",
          objCondition: objCondition,
        };
        this.handleCrudAPIAdmin(objParam).then(data => {
          if (data.ok == true)
            return this.$router.push({ name: 'admin-user-post', params:{success_remove:"1"}});
        });
    },

    async fetchData(objCondition = {}) {
      let objPagram = {
        method: "get",
        urlAPi:"user-post/detail/"+this.$route.query.id,
        objCondition: objCondition
      };
      let dataResult  = await this.handleCrudAPIAdmin(objPagram)
      this.post = dataResult.data;
      this.username = dataResult.data.get_user;
      this.reportHistory = dataResult.data.get_post_reports;
      this.images = dataResult.data.get_files;
    },
    zoomImage(path) {
      this.zoomImageSrc = path;
      this.modalShow = true;
    },
    
  },
  async created() {
    await this.fetchData();
    
    this.$store.dispatch('setPageData', {
          bread_crumbs: [
            {
                text: "自由投稿管理",
                to: "/admin/user-post"
            },
            {
                text: "詳細",
                to: "#"
            }
        ],
        title: "自由投稿管理/詳細",
        sidebar: 'user-post'
    });
  },
})
</script>

<style lang="css">
    .input-group-text, th {
    width: 200px;
    }

    .btn-back {
        width: 150px;
    }

    .btn-group .btn {
        margin-right: 10px;
    }

    .card-img-top {
        height: 200px;
    }

    .btn-submit {
        width: 200px;
    }
    
    .card-img-top {
      height: initial !important;
    }
</style>
