<template>
  <div>
    
    
    当アプリの運営に際し、お客様のプライバシーを尊重し個人情報に対して <br>
    十分な配慮を行うとともに、大切に保護し、適正な管理を行うことに努めております。  <br>
     <br> 
    【個人情報の利用目的】  <br>
       <br>
        a）お客様のご要望に合わせたサービスをご提供するための各種ご連絡。  <br>
        b）お問い合わせいただいたご質問への回答のご連絡。  <br>
  <br>
    公正かつ適正な手段で、上記目的に必要となる個人情報を収集します。  <br>
    要配慮個人情報を取得する際は、ご本人の同意を得るものとします。  <br>
    取得した個人情報・要配慮個人情報は、ご本人の同意なしに目的以外では利用しません。  <br>
    情報が漏洩しないよう対策を講じ、従業員だけでなく委託業者も監督します。  <br>
    国内外を問わず、ご本人の同意を得ずに第三者に情報を提供しません。  <br>
    ご本人からの求めに応じ情報を開示します。  <br>
    公開された個人情報が事実と異なる場合、訂正や削除に応じます。  <br>
    個人情報の取り扱いに関する苦情に対し、適切・迅速に対処します。  <br>
    本個人情報保護方針の適用範囲は本アプリ内のみとします。 <br>
    
  </div>
</template>


<script lang="js">
  import Vue from 'vue'
  export default Vue.extend({
    data() {
      return {
        
      };
    },
  })
</script>
