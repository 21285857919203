<template>
  <div>
    <!-- <span>情報を更新しました</span> -->
    <b-alert variant="success" show v-if="update_success"
      >情報を更新しました</b-alert
    >
    <b-container class="mt-2" fluid>
      <div class="text-center" v-if="!loaded">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <b-row class="mt-2 pb-3">
        <b-col md="6" class="text-right pl-0">
          <b-button
            variant="outline-success"
            class="btn-submit mr-4"
            v-b-modal.update-model
            >更新</b-button
          >
          <b-button
            href="#"
            variant="outline-danger"
            v-on:click="disableUser()"
            v-b-modal.update-model
          >
            {{
              user.status == 1
                ? "アカウントを無効にする"
                : "アカウントを有効にする"
            }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <!-- ID input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >ID</b-col
            >
            <b-col md="8" class="p-0 no-border-radius-left">
              <b-form-input
                class="no-border-radius-left"
                v-model="user.index"
                disabled
              ></b-form-input>
            </b-col>
          </b-row>
          <!-- End ID input -->

          <!-- Created input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >登録年月日</b-col
            >
            <b-col
              md="8"
              class="no-border-radius-left input-group-text height-initial"
            >
              {{ formatDate(user.created_at) }}
            </b-col>
          </b-row>
          <!-- End Created input -->

          <!-- Status input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >ステータス</b-col
            >
            <b-col md="8" class="p-0 no-border-radius-left border-form">
              <b-form-radio-group
                class="mt-2 ml-2"
                id="radio-group-1"
                v-model="user.status"
                :options="statusList"
                name="radio-options"
              ></b-form-radio-group>
            </b-col>
          </b-row>
          <!-- End Status input -->

          <!-- Payment status input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >決済状態</b-col
            >
            <b-col
              md="8"
              class="no-border-radius-left input-group-text height-initial"
            >
              {{ user.expires_date.length == 0 ? "無料会員" : "有料会員" }}
            </b-col>
          </b-row>
          <!-- End Payment status input -->

          <!-- Username input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >ユーザーネーム</b-col
            >
            <b-col md="8" class="p-0 no-border-radius-left">
              <b-form-input
                class="no-border-radius-left"
                v-model="user.username"
                disabled
              ></b-form-input>
            </b-col>
          </b-row>
          <!-- End Username input -->

          <!-- department input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >所属</b-col
            >
            <b-col
              md="8"
              class="no-border-radius-left input-group-text height-initial"
            >
              {{ getDeparment(user.department) }}
            </b-col>
          </b-row>
          <!-- End department input -->

          <!-- Skills input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >スキル/資格</b-col
            >
            <b-col
              md="8"
              class="no-border-radius-left input-group-text height-initial d-block"
            >
              <div
                class="ml-2 text-left"
                size="sm"
                v-for="skill in user.get_certificates"
                :key="skill._id"
                pill
                :variant="getSkillButton(skill.status)"
              >
                {{ skill.get_skill.name }}
              </div>
            </b-col>
          </b-row>
          <!-- End Skills input -->

          <!-- Area input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >活動エリア</b-col
            >
            <b-col md="8" class="p-0 no-border-radius-left">
              <b-form-input
                class="no-border-radius-left"
                v-model="userArea"
                disabled
              ></b-form-input>
            </b-col>
          </b-row>
          <!-- End area input -->

          <!-- Technique input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >得意分野</b-col
            >
            <b-col md="8" class="p-0 no-border-radius-left">
              <b-form-input
                class="no-border-radius-left"
                v-model="user.genre"
                disabled
              ></b-form-input>
            </b-col>
          </b-row>
          <!-- End Technique input -->

          <!-- Jobs input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >経歴</b-col
            >
            <b-col md="8" class="p-0 no-border-radius-left">
              <b-form-input
                class="no-border-radius-left"
                v-model="user.experience"
                disabled
              ></b-form-input>
            </b-col>
          </b-row>
          <!-- End Jobs input -->

          <!-- Birthday input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >生年月日</b-col
            >
            <b-col
              md="8"
              class="no-border-radius-left input-group-text height-initial"
            >
              <span v-if="user.birthday">{{ formatDate(user.birthday) }}</span>
            </b-col>
          </b-row>
          <!-- End Birthday input -->

          <!-- Comment input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >コメント</b-col
            >
            <b-col md="8" class="p-0 no-border-radius-left">
              <b-form-input
                class="no-border-radius-left"
                v-model="user.comment"
                disabled
              ></b-form-input>
            </b-col>
          </b-row>
          <!-- End Comment input -->

          <!-- Website input -->
          <b-row class="mt-2">
            <b-col md="4" class="input-group-text no-border-radius-right"
              >ウェブサイト</b-col
            >
            <b-col md="8" class="p-0 no-border-radius-left border-form">
              <b-button
                class="ml-2"
                size="sm"
                v-for="website in user.websites"
                :key="website"
                pill
                variant="outline-primary"
                v-show="website"
              >
                <a :href="getLinkWebSite(website)" target="blank">{{
                  website
                }}</a>
              </b-button>
            </b-col>
          </b-row>
          <!-- End Website input -->
        </b-col>

        <b-col md="6" class="p-0 pl-3 mt-2">
          <!-- Avatar input -->
          <div>
            <b-input-group>
              <template #prepend>
                <b-input-group-text>プロフィール画像</b-input-group-text>
              </template>
              <b-img
                thumbnail
                fluid
                :src="mapAVATAR_URL(user.avatar)"
                width="200"
                alt="Image 1"
              ></b-img>
            </b-input-group>
          </div>
          <!-- End Avatar input -->
        </b-col>
      </b-row>

      <!-- Skill validation -->

      <!-- CMND input -->
      <b-row
        class="mt-2"
        v-if="user.get_id_images && user.get_id_images.length > 0"
      >
        <b-col md="2" class="input-group-text no-border-radius-right"
          >本人確認書類</b-col
        >
        <b-col md="4" class="p-3 no-border-radius-left border-form">
          <b-img
            class="mb-2"
            v-if="user.get_id_images && user.get_id_images.length > 0"
            thumbnail
            fluid
            v-bind:src="mapAWS_URL(user.get_id_images[0].path)"
            alt="Image 1"
            v-on:click="zoomImage(user.get_id_images[0].path)"
          ></b-img>
          <b-img
            class="mb-2"
            v-if="user.get_id_images && user.get_id_images.length == 0"
            thumbnail
            fluid
            v-bind:src="'/f_admin/upload/no-image.png'"
            alt="Image 1"
          ></b-img>
          <!-- <b-form-group>
                            <b-form-radio-group
                                :id="'radio-validate-id-card-' + user._id"
                                v-model="user.ID_validate"
                                :options="validateStatusList"
                                :name="'id-card-' + user._id"
                            ></b-form-radio-group>
                        </b-form-group> -->
          <b-row class="ml-1">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              v-if="user.ID_validate == 0"
            >
              <b-form-radio-group
                :id="'radio-validate-id-card-' + user._id"
                v-model="current_id_validation"
                :options="validateStatusList"
                :aria-describedby="ariaDescribedby"
                :name="'id-card-' + user._id"
              ></b-form-radio-group>
            </b-form-group>

            <span v-if="user.ID_validate != 0">{{
              user.ID_validate == 1 ? "認証済" : "認証しない"
            }}</span>
            <b-form-radio
              v-model="IDselected"
              name="some-radios"
              class="d-inline ml-3"
              value="true"
              v-if="user.ID_validate != 0"
            >
              {{ user.ID_validate == 1 ? "認証しない" : "認証する" }}
            </b-form-radio>
          </b-row>

          <b-button
            href="#"
            variant="outline-secondary"
            v-on:click="changeIDValidate()"
            >更新</b-button
          >
        </b-col>
      </b-row>
      <!-- End CMND input -->

      <!--  Free News -->
      <b-row class="mt-2">
        <b-col md="2" class="input-group-text no-border-radius-right"
          >自由投稿</b-col
        >
        <b-col md="10">
          <router-link
            :to="{ name: 'admin-user-post', query: { user_id: user._id } }"
          >
            <b-button variant="outline-secondary" style="width: 150px"
              >自由投稿ページ</b-button
            >
          </router-link>
        </b-col>
      </b-row>
      <!-- End Free Skill -->

      <!--  Job request -->
      <b-row class="mt-2">
        <b-col md="2" class="input-group-text no-border-radius-right"
          >お仕事依頼</b-col
        >
        <b-col md="10">
          <router-link
            :to="{ name: 'admin-jobs', query: { user_id: user._id } }"
          >
            <b-button variant="outline-secondary" style="width: 150px"
              >お仕事投稿ページ</b-button
            >
          </router-link>
        </b-col>
      </b-row>
      <!-- End Job request -->

      <!--  Remarks -->
      <b-row class="mt-2">
        <b-col md="2" class="input-group-text no-border-radius-right"
          >備考</b-col
        >
        <b-col md="10" class="p-0">
          <textarea
            maxlength="10000"
            v-model="user.note"
            rows="10"
            cols="50"
            class="form-control"
            style="resize: none; overflow-y: scroll; height: 150px;"
          >
          </textarea>
        </b-col>
      </b-row>
      <!-- End Remarks -->

      <!--  Report history -->
      <b-row class="mt-2">
        <b-col md="2" class="input-group-text no-border-radius-right"
          >通報履歴</b-col
        >
        <b-col md="10" class="border-form">
          <p v-for="report in user.get_own_reports" :key="report._id">
            {{ formatDate(report.created_at) }}
            -
            <span v-if="report.reason">{{ report.reason }}</span>
            <span v-if="report.content">{{ report.content }}</span>
          </p>
        </b-col>
      </b-row>
      <!-- End Report history -->

      <!--  Buttons -->
      <b-row class="mt-2 pb-3">
        <b-col md="6" class="text-left pl-0">
          <b-button
            variant="outline-success"
            class="btn-submit"
            v-b-modal.update-model
            >更新</b-button
          >
          <b-button
            href="#"
            variant="outline-danger"
            v-on:click="disableUser()"
            v-b-modal.update-model
          >
            {{
              user.status == 1
                ? "アカウントを無効にする"
                : "アカウントを有効にする"
            }}
          </b-button>
        </b-col>
        <b-col md="6" class="text-right">
          <router-link
            :to="{ name: 'admin-users', query: { user_id: user._id } }"
          >
            <b-button variant="outline-secondary">一覧へ戻る</b-button>
          </router-link>
        </b-col>
      </b-row>
      <!-- End Buttons -->
    </b-container>
    <b-modal id="update-model" @ok="updateUser" cancel-title="キャンセル"
      >こちらの内容で登録いたします。</b-modal
    >
    <b-modal
      id="bv-image-modal"
      v-model="modalShow"
      size="lg"
      :hide-header="true"
      :hide-footer="true"
    >
      <b-img
        class="w-100"
        thumbnail
        fluid
        v-bind:src="mapAWS_URL(zoomImageSrc)"
      ></b-img>
    </b-modal>

    <b-modal
      v-model="modalIDStatusShow"
      id="update-status-model"
      @ok="idCardValidate()"
      cancel-title="キャンセル"
      >ステータスを更新しますか</b-modal
    >
    <b-modal
      v-model="modalSkillStatusShow"
      id="update-status-model"
      @ok="skillValidate()"
      cancel-title="キャンセル"
      >ステータスを更新しますか</b-modal
    >
  </div>
</template>

<script lang="js">
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import adminMixin from "../../../mixin/AdminMixin";
import { AWS_URL, SERVER_URL } from "../../../common/config";
export default Vue.extend({
    data() {
        return {
            remarks: '',
            value: '',
            selected: 'first',
            statusList: [
                { text: '有効', value: 1 },
                { text: '無効', value: 0 },
                { text: '退会', value: 2 },
            ],
            validateStatusList: [
                { text: '認証する', value: 1 },
                { text: '認証しない', value: 2 },
            ],
            skills: [],
            birthday: '',
            reports: [
                {id: 1, time:'2020/01/10', content: 'spam'},
                {id: 2, time:'2020/02/11', content: 'spam'},
                {id: 3, time:'2020/03/12', content: '***'},
                {id: 4, time:'2020/04/13', content: 'spam'},
                {id: 5, time:'2020/05/14', content: 'spam'},
                {id: 6, time:'2020/06/15', content: '***'},
            ],
            loaded: false,
            user: {},
            userArea: '',
            defaultPassword: '12345678',
            modalShow: false,
            zoomImageSrc: '',
            update_success: false,
            IDselected: false,
            modalIDStatusShow: false,
            modalSkillStatusShow: false,
            current_skill_id: "",

        };
    },
    mixins: [adminMixin],
    computed: {
        ...mapGetters([
            'getTokens',
        ]),
    },
     methods: {
        ...mapActions(['handleCrudAPIAdmin']),
        async fetchData(objCondition = {}) {
            let users = this.$route.query.users;
            objCondition.users = typeof users == "string" ? [users] : users;
            let objPagram = {
                method: "get",
                urlAPi: "user_management/"+users,
                objCondition: objCondition
            };
            let dataResult = await this.handleCrudAPIAdmin(objPagram);
            this.user = dataResult.data;
            this.user.get_certificates = this.user.get_certificates.map(
                skill => ({
                    ...skill,
                    old_status: skill.status
                })
            )
            this.userArea = this.areaText(this.user.get_connect_areas);
            this.loaded = true;
        },
        async updateStatus(objCondition = {}) {
           let objPagram = {
                method: "put",
                urlAPi: "validate/detail",
                objCondition: objCondition
            };
            await this.handleCrudAPIAdmin(objPagram);
            this.loaded = true;
            this.update_success = true;
            window.scrollTo(0,0);
            // this.modalShow = true;
        },
        changeIDValidate() {
            this.modalIDStatusShow = true;
        },
        changeSkillValidate(skill) {
            this.current_skill_id = skill._id;
            this.current_skill_status = skill.status;
            if(skill.status === "true") {
                this.current_skill_status = skill.old_status == 1 ? 2 : 1;
            }
            this.modalSkillStatusShow = true;
        },
        idCardValidate: function() {
            let status = this.current_id_validation;
            if(this.IDselected)
                status = this.user.ID_validate == 1 ? 2 : 1;
            let data = {
                _id : this.user._id,
                status : status,
                type : 0
            };
            this.IDselected = false;
            this.loaded = false;
            this.updateStatus(data);
            this.user.ID_validate = status;
        },
        skillValidate: function() {
            let data = {
                _id : this.current_skill_id,
                status : this.current_skill_status,
                type : 1
            };
            for(let skill of this.user.get_certificates)
                if(skill._id == this.current_skill_id) {
                    skill.old_status = this.current_skill_status;
                    skill.status = this.current_skill_status;
                }
            this.loaded = false;
            this.updateStatus(data);
        },
        getSkillButton: function(type) {
            switch (type) {
                case 0:
                    return "primary";
                case 1:
                    return "success";
                case 2:
                    return "danger";
            }
        },

        areaText(areas = "") {
            if(areas.length == 0)
                return "";
            let area_text = [];
            for(let area of areas) {
                area_text.push(area.name);
            }
            return area_text.join(", ");
        },

        async disableUser() {
            if(this.user.status == 1)
                this.user.status = 0;
            else
                this.user.status = 1;
        },

        async updateUser() {
            this.loaded = false;
            let users = this.$route.query.users;
            let objCondition = {
                comment : this.user.comment,
                status: this.user.status,
                note: this.user.note,
                email: this.user.email
            };
            let objPagram = {
                    method: "put",
                    urlAPi: "user_management/"+users,
                    objCondition: objCondition
            };
            let dataResult = await this.handleCrudAPIAdmin(objPagram);
            if(dataResult.error) {
                this.loaded = true;
                alert("このメールアドレスは既に登録済のため、有効にできません。");
                return;
            }
            this.loaded = true;
            this.$router.push({ name: 'admin-users', params:{success:"1"}});
        },
        getDeparment(department) {
            switch(department) {
                case 0:
                    return '保険会社専属';
                case 1:
                    return '保険代理店';
                default:
                    return 'その他';
            }
        },

        mapAWS_URL: function(url) {
            if(url)
                return AWS_URL + url;
            return '/f_admin/upload/no-image.png';
        },

        mapSERVER_URL: function(url) {
            if(url)
                return SERVER_URL + url;
            return '/f_admin/upload/avatar.png';
        },

        mapAVATAR_URL: function(url) {
            if(url) {
                if(url.search("https://") != -1) {
                    return url;
                }
                else {
                    return AWS_URL + url;
                }
            }
            return '/f_admin/upload/avatar.png';
        },

        getLinkWebSite: function(url) {
            if(url && url.search("https://") == -1 && url.search("http://") == -1)
                return "https://" + url;
            return url;
        },

        zoomImage(path) {
            this.zoomImageSrc = path;
            this.modalShow = true;
        },
    },
    async created() {
        await this.fetchData();
    },
})
</script>

<style lang="css" scoped>
.input-group-text,
th {
  width: 200px;
}

.search {
  width: initial;
}

.btn-group .btn {
  margin-right: 10px;
}

.card-img-top {
  height: 200px;
}

.btn-submit {
  width: 200px;
}
</style>
